import { Component, HostListener } from '@angular/core';
import { VisitsService } from '../../shared/services/visits.service';
import { AppSessionService } from '../../../../core/app-session-service/app-session.service';

@Component({
  selector: 'app-visits-layout',
  templateUrl: './visits-layout.component.html',
  styleUrls: ['./visits-layout.component.scss'],
})
export class VisitsLayoutComponent {
  constructor(private visitsService: VisitsService, private sessionService: AppSessionService) {}

  /*
    Getting window size for updating long text to 'some text...'
    and detecting mobile size for rebuild template for mobile
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    const windowRef: Window = event.currentTarget as Window;
    this.visitsService.isMobile = windowRef.innerWidth < 375;
    this.visitsService.innerHeight = window.innerHeight;
    this.visitsService.innerWidth = window.innerWidth;
  }

  async ngOnInit() {
    this.visitsService.isMobile = window.innerWidth < 375;
    const visitInfo = await this.visitsService.getOpenedVisit();
    this.sessionService.currentVisitID = visitInfo ? +visitInfo.id : visitInfo;
  }
}
