<div class="visits-popup-header">
  Сейчас открыт визит без<br>
  подключения к аккаунту
</div>
<div class="visits-popup-info">
  <img src="../../../../../assets/images/avatar.png">
  <div class="visits-popup-info-text">
    {{ clientInfo?.name ? clientInfo?.name : 'Загрузка' }}<br>
    <span>{{ clientInfo?.email ? clientInfo?.email : '' }}</span>
  </div>
</div>
<div class="visits-popup-actions">
  <button class="visits-popup-actions-end" (click)="closeVisit()">Завершить визит</button>
</div>
