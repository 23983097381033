<div class="modal-end">
  <div class="modal-end-title">Информация о новом клиенте</div>
  <app-visits-modal-form-input
    [formData]="formData"
    formDataField="email"
    title="Email клиента"
    [isError]="formDataErrors['email']"
    [isSmall]="true"
  >
  </app-visits-modal-form-input>
  <app-visits-modal-form-input
    [formData]="formData"
    formDataField="name"
    title="Имя клиента"
    [isError]="formDataErrors['name']"
    [isSmall]="true"
  >
  </app-visits-modal-form-input>
<!--  <app-visits-modal-form-selector-->
<!--    [formData]="formData"-->
<!--    formDataField="country"-->
<!--    title="Страна"-->
<!--    [values]="getTmpFormSelectorData()"-->
<!--    [isError]="formDataErrors['country']"-->
<!--    [isBlocked]="true"-->
<!--  >-->
<!--  </app-visits-modal-form-selector>-->
<!--  <app-visits-modal-form-selector-->
<!--    [formData]="formData"-->
<!--    formDataField="city"-->
<!--    title="Город"-->
<!--    [values]="getTmpFormSelectorData()"-->
<!--    [isError]="formDataErrors['city']"-->
<!--    [isBlocked]="true"-->
<!--  >-->
<!--  </app-visits-modal-form-selector>-->
<!--  <app-visits-modal-form-selector-->
<!--    [formData]="formData"-->
<!--    formDataField="dispatcherAddress"-->
<!--    title="Вывеска"-->
<!--    [values]="getTmpFormSelectorData()"-->
<!--    [isError]="formDataErrors['dispatcherAddress']"-->
<!--    [isBlocked]="true"-->
<!--  >-->
<!--  </app-visits-modal-form-selector>-->
<!--  <app-visits-modal-form-selector-->
<!--    [formData]="formData"-->
<!--    formDataField="type"-->
<!--    title="Тип торговой точки"-->
<!--    [values]="getTmpFormSelectorData()"-->
<!--    [isError]="formDataErrors['type']"-->
<!--    [isBlocked]="true"-->
<!--  >-->
<!--  </app-visits-modal-form-selector>-->
  <div [hidden]="!isInternalError" class="modal-internal-error">
    Мы сожалеем, произошел сбой в системе<br>
    Попробуйте еще раз спустя некоторое время
  </div>
  <div class="modal-end-action-buttons">
    <button class="modal-end-action-back" (click)="visitsService.closeModal()">Отмена</button>
    <button class="modal-end-action-next" [disabled]="isInternalError" (click)="continueCreateClient()">Продолжить</button>
  </div>
</div>
