<div class="visits-modal-continue-wrapper">
  <h1 class="visits-modal-continue-header">Продолжить визит</h1>
  <h2 class="visits-modal-continue-info">
    Вы хотите продолжить?
  </h2>
  <div class="visits-modal-continue-actions">
    <button class="visits-modal-continue-action-cancel" (click)="visitsService.closeModal()">Отмена</button>
    <button class="visits-modal-continue-action-reopen" (click)="continueDraftedVisit()">Продолжить визит</button>
  </div>
</div>
