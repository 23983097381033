import { Component, OnInit } from '@angular/core';
import { VisitsService } from '../../../shared/services/visits.service';
import { RowData, TableDataInterface } from '../../../shared/interfaces/table-data.interface';
import { VisitsJournalDataInterface } from '../../../shared/interfaces/visits-journal-data.interface';
import { DateStringToDatePipe } from '../../../shared/pipes/date-string-to-date.pipe';

@Component({
  selector: 'app-visits-express-panel-list',
  templateUrl: './visits-express-panel-list.component.html',
  styleUrls: ['./visits-express-panel-list.component.scss'],
})
export class VisitsExpressPanelListComponent implements OnInit {
  public normalTableData?: TableDataInterface;
  public adaptiveTableData?: TableDataInterface;
  constructor(public visitsService: VisitsService, private dateStringToDatePipe: DateStringToDatePipe) {}

  async ngOnInit() {
    this.normalTableData = {
      columnCount: 6,
      titles: ['Дата', 'Номер', 'Клиент', 'Время начала', 'Время завершения', 'Детали визита'],
      rows: [],
    };

    this.adaptiveTableData = {
      columnCount: 6,
      titles: ['Дата', 'Номер', 'Клиент', 'Время начала</br>Время завершения', 'Детали визита'],
      rows: [],
    };

    await this.generateVisitsJournalTableData();
    this.normalTableData = Object.assign({}, this.normalTableData);
  }

  private async generateVisitsJournalTableData() {
    const visitsJournal: VisitsJournalDataInterface[] = await this.visitsService.getVisits();
    for (const row of visitsJournal) {
      this.normalTableData?.rows?.push(await this.getJournalTableRowData(row));
      this.adaptiveTableData?.rows?.push(await this.getJournalAdaptiveTableRowData(row));
    }
  }

  private async getJournalTableRowData(rowData: VisitsJournalDataInterface): Promise<RowData> {
    const clientInfo = await this.visitsService.getClientInfo(rowData.client_email);
    return {
      rowData: [
        {
          text: rowData.created_at,
          dateType: 'date',
        },
        {
          text: rowData.visit_number,
        },
        {
          text: clientInfo.name,
        },
        {
          text: this.visitsService.convertDateToString(rowData.time_start, 'date-time'),
        },
        {
          text: this.visitsService.convertDateToString(rowData.time_end || '', 'date-time'),
        },
        {
          text: 'Детали визита',
          link: {
            linkSrc: ['/', 'visits', 'express-panel', 'list', `${rowData.id}`],
            linkType: 'open',
          },
        },
      ],
    };
  }

  private async getJournalAdaptiveTableRowData(rowData: VisitsJournalDataInterface): Promise<RowData> {
    const clientInfo = await this.visitsService.getClientInfo(rowData.client_email);
    return {
      rowData: [
        {
          text: rowData.created_at,
          dateType: 'date',
        },
        {
          text: rowData.visit_number,
        },
        {
          text: clientInfo.name,
        },
        {
          text: `${this.visitsService.convertDateToString(
            rowData.time_start,
            'date-time',
          )}</br>${this.visitsService.convertDateToString(rowData.time_end, 'date-time')}
          `,
        },
        {
          text: 'Детали визита',
          link: {
            linkSrc: ['/', 'visits', 'express-panel', 'list', `${rowData.id}`],
            linkType: 'open',
          },
        },
      ],
    };
  }

  private transformDateToDateTimeString(date: Date | undefined): string {
    if (date) {
      return `${date.getHours()}:${date.getMinutes()} ${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
    } else {
      return '';
    }
  }

  public get tableData(): TableDataInterface | undefined {
    if (this.visitsService.innerWidth < 1000 && this.visitsService.innerWidth > 540) {
      return this.adaptiveTableData;
    }
    return this.normalTableData;
  }

  private getDateFromDate(date: Date | undefined): string {
    if (date) {
      return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
    } else {
      return '';
    }
  }
}
