import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visits-popup',
  templateUrl: './visits-popup.component.html',
  styleUrls: ['./visits-popup.component.scss'],
})
export class VisitsPopupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
