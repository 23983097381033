import { Component } from '@angular/core';

@Component({
  selector: 'app-visits-popup-my',
  templateUrl: './visits-popup-my.component.html',
  styleUrls: ['./visits-popup-my.component.scss'],
})
export class VisitsPopupMyComponent {
  constructor() {}
}
