import { Injectable } from '@angular/core';
import {
  GlobalSessionOptionsInterface,
  ModalSessionOptionsInterface,
  PanelSessionOptionsInterface,
  PopupSessionOptionsInterface,
  SessionOptionsInterface,
} from '../interfaces/message-data/session-options.interface';
import { ModalDataStepTypeInterface } from '../interfaces/message-data/modal-data-step-type.interface';
import { MessageDataVisitsResponseInterface } from '../interfaces/message-data/message-data-visits-response.interface';
import { MessageDataModalTypeInterface } from '../interfaces/message-data/message-data-modal-type.interface';
import { Router } from '@angular/router';
import { ModuleRoutes } from '../../app-export.constant';

@Injectable()
export class AppSessionService {
  private globalOptions?: GlobalSessionOptionsInterface;
  private modalOptions?: ModalSessionOptionsInterface;
  private popupOptions?: PopupSessionOptionsInterface;
  private panelOptions?: PanelSessionOptionsInterface;

  constructor(private router: Router) {}

  public async parseOptions(sessionData: SessionOptionsInterface) {
    this.globalOptions = {
      csrf: sessionData.csrf,
      bearerKey: sessionData.bearerKey,
      cookieString: sessionData.cookieString,
      visitId: null,
    };

    if (sessionData?.visitId) {
      this.globalOptions.visitId = sessionData.visitId;
    }

    if (sessionData.type === 'modal') {
      this.modalOptions = {
        type: sessionData.type,
        lastIframeId: sessionData.lastIframeId,
        modal: sessionData.modal,
        clientEmail: sessionData.clientEmail,
      };
    }

    if (sessionData.type === 'express-panel') {
      this.panelOptions = {
        type: sessionData.type,
        lastIframeId: sessionData.lastIframeId,
      };
    }

    if (sessionData.type === 'popup') {
      this.popupOptions = {
        type: sessionData.type,
        lastIframeId: sessionData.lastIframeId,
        popup: sessionData.popup,
      };
    }

    await this.routeToNeededModule(sessionData);
  }

  public get sessionGlobal(): GlobalSessionOptionsInterface | undefined {
    return this.globalOptions;
  }

  public get sessionModal(): ModalSessionOptionsInterface | undefined {
    return this.modalOptions;
  }

  public get sessionPanel(): PanelSessionOptionsInterface | undefined {
    return this.panelOptions;
  }

  public get sessionPopup(): PopupSessionOptionsInterface | undefined {
    return this.popupOptions;
  }

  public set currentVisitID(id: number | null) {
    if (this.globalOptions && !this.globalOptions?.visitId) {
      this.globalOptions.visitId = id;
    }
  }

  private getResponseData(
    isNeededNextStep: boolean,
    modalType: MessageDataModalTypeInterface | null,
    clientEmail?: string,
    lastIframeId?: string,
  ): MessageDataVisitsResponseInterface {
    return this.generateResponseData(this.generateNextStepData(isNeededNextStep, modalType), clientEmail, lastIframeId);
  }

  private generateResponseData(
    nextStep: ModalDataStepTypeInterface | null,
    clientEmail?: string,
    lastIframeId?: string,
  ): MessageDataVisitsResponseInterface {
    return {
      title: 'ngVisits',
      nextStep,
      clientEmail,
      lastIframeId,
    };
  }

  private generateNextStepData(
    isNeeded: boolean,
    modalType: MessageDataModalTypeInterface | null,
  ): ModalDataStepTypeInterface | null {
    if (isNeeded) {
      return {
        type: 'modal',
        modalType: modalType,
      };
    } else {
      return null;
    }
  }

  public async routeToNeededModule(sessionData: SessionOptionsInterface): Promise<void> {
    const path = ['/'];
    ModuleRoutes[sessionData.type]?.forEach(str => path.push(str));

    if (sessionData.type === 'modal') {
      const sessionPath: string[] = [];
      if (sessionData.modal?.includes('/')) {
        sessionPath.push(...sessionData.modal?.split('/'));
      } else {
        sessionPath.push(sessionData.modal ? sessionData.modal : '');
      }
      path.push(...sessionPath);
    }

    if (sessionData.type === 'popup') {
      path.push(sessionData.popup ? sessionData.popup : '');
    }

    await this.router.navigate(path);
  }

  public sendResponseToIframe(
    isNeededNextStep: boolean,
    modalType: MessageDataModalTypeInterface | null,
    clientEmail?: string,
    lastIframeId?: string,
    visitId?: number,
    isNeededRefresh?: boolean,
  ): void {
    const data = this.getResponseData(isNeededNextStep, modalType, clientEmail, lastIframeId);
    window.top?.postMessage(Object.assign(data, { visitId, isNeededRefresh }), '*');
  }
}
