<div class="modal-start">
  <div class="modal-start-title">Параметры визита</div>
  <div class="modal-start-progress">
    <div class="modal-start-progress-info">
      <div class="modal-start-progress-info-item">{{ progressPercent }}</div>
      <div class="modal-start-progress-info-item">Шаг {{ step }} из 3</div>
    </div>
    <div class="modal-start-progress-bar">
      <span [style]="{ width: progressPercent }"></span>
    </div>
  </div>
  <form [ngSwitch]="step" class="modal-start-form">
    <ng-container *ngSwitchCase="1">
      <ng-content *ngTemplateOutlet="stepOneFormTemplate"></ng-content>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <ng-content *ngTemplateOutlet="stepTwoFormTemplate"></ng-content>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <ng-content *ngTemplateOutlet="stepThreeFormTemplate"></ng-content>
    </ng-container>
  </form>
  <div [hidden]="!isInternalError" class="modal-internal-error">
    Мы сожалеем, произошел сбой в системе<br>
    Попробуйте еще раз спустя некоторое время
  </div>
  <div class="modal-start-action-buttons">
    <button class="modal-start-action-back" [disabled]="isInternalError && step !== 1" (click)="switchStep(false)">
      {{ step === 1 ? 'Отмена' : 'Назад' }}
    </button>
    <button class="modal-start-action-next" [disabled]="isInternalError" (click)="switchStep(true)">
      {{ step === 3 ? 'Подключиться' : 'Продолжить' }}
    </button>
  </div>
</div>


<ng-template #stepOneFormTemplate>
  <div class="modal-start-form-field">
    <div class="field-title">Вид визита</div>
    <app-visits-modal-form-radio
      [formData]="formData"
      formDataField="visitType"
      title="Вид визита"
      [values]="['On-line', 'Off-line']"
      [isError]="formDataErrors['visitType']"
    >
    </app-visits-modal-form-radio>
  </div>
  <div class="modal-start-form-field">
    <div class="field-title">Доставка</div>
    <app-visits-modal-form-selector
      [formData]="formData"
      formDataField="deliveryTo"
      title="Доставить до"
      [values]="deliveryToOptions || []"
      [isError]="formDataErrors['deliveryTo']"
      [isBlocked]="!deliveryToOptions?.length"
      >
    </app-visits-modal-form-selector>
    <app-visits-modal-form-selector
      [formData]="formData"
      formDataField="deliveryAddress"
      title="Адрес доставки"
      [values]="addressesNames || []"
      [isError]="formDataErrors['deliveryAddress']"
      [isBlocked]="!addressesNames?.length"
      >
    </app-visits-modal-form-selector>
  </div>
</ng-template>

<ng-template #stepTwoFormTemplate>
  <div class="modal-start-form-field">
    <div class="field-title">Отгрузка</div>
    <app-visits-modal-form-selector
      [formData]="formData"
      formDataField="dispatcher"
      title="Отгрузить вперед от"
      [values]="companyNames || []"
      [isError]="formDataErrors['dispatcher']"
      [isBlocked]="!companyNames?.length"
    >
    </app-visits-modal-form-selector>
    <app-visits-modal-form-selector
      [formData]="formData"
      formDataField="dispatcherAddress"
      title="Адрес отгрузки"
      [values]="companyAddresses || []"
      [isError]="formDataErrors['dispatcherAddress']"
      [isBlocked]="!companyAddresses?.length"
    >
    </app-visits-modal-form-selector>
  </div>
</ng-template>

<ng-template #stepThreeFormTemplate>
  <div class="modal-start-form-field">
    <div class="field-title">Цель визита</div>
    <app-visits-modal-form-selector
      [formData]="formData"
      formDataField="visitGoal"
      title="Цель визита"
      [values]="goals || []"
      [isError]="formDataErrors['visitGoal']"
    >
    </app-visits-modal-form-selector>
    <app-visits-modal-form-input
      [formData]="formData"
      formDataField="visitComment"
      title="Комментарий"
      [isError]="formDataErrors['visitComment']"
    ></app-visits-modal-form-input>
  </div>
</ng-template>
