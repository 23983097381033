import { Component, Input } from '@angular/core';
import { VisitFormDataInterface } from '../../../shared/interfaces/visit-form-data.interface';

@Component({
  selector: 'app-visits-modal-form-radio',
  templateUrl: './visits-modal-form-radio.component.html',
  styleUrls: ['./visits-modal-form-radio.component.scss'],
})
export class VisitsModalFormRadioComponent {
  @Input() public formData!: VisitFormDataInterface;
  @Input() public formDataField!: string;
  @Input() public values!: string[];
  @Input() public isError!: boolean | string;

  constructor() {}

  public get errorText(): string {
    return typeof this.isError === 'string' ? this.isError : 'Выберите необходимое значение';
  }
}
