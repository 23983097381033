import { Component, Input } from '@angular/core';
import { TableDataInterface } from '../../shared/interfaces/table-data.interface';
import { VisitsService } from '../../shared/services/visits.service';

@Component({
  selector: 'app-visits-table',
  templateUrl: './visits-table.component.html',
  styleUrls: ['./visits-table.component.scss'],
})
export class VisitsTableComponent {
  @Input() public tableData!: TableDataInterface | undefined;
  @Input() public linkRowsCount!: number;

  constructor(public visitsService: VisitsService) {}

  get gridTemplateColumns(): string {
    const tableLength = this.tableData?.titles.length ? this.tableData?.titles.length : 0;
    const linksStr = this.linkRowsCount > 1 ? ` repeat(${this.linkRowsCount}, 190px)` : '190px';
    return `repeat(${this.linkRowsCount ? tableLength - this.linkRowsCount : tableLength}, auto) ${
      this.linkRowsCount ? linksStr : ''
    }`;
  }

  public get neededTextLength(): number {
    return this.visitsService.innerWidth <= 990 ? 20 : 30;
  }

  public getNewObjectToCellTemplate(i: number, cell: object) {
    return Object.assign({ index: i }, cell);
  }
}
