import { Component, OnInit } from '@angular/core';
import { VisitsService } from '../../shared/services/visits.service';

@Component({
  selector: 'app-visits-modal',
  templateUrl: './visits-modal.component.html',
  styleUrls: ['./visits-modal.component.scss'],
})
export class VisitsModalComponent implements OnInit {
  constructor(public visitsService: VisitsService) {}
  async ngOnInit() {
    this.visitsService.goalsAndReachings = await this.visitsService.getGoalsAndReachings();
  }
}
