import { Component, DoCheck, OnInit } from '@angular/core';
import { VisitFormDataInterface } from '../../../shared/interfaces/visit-form-data.interface';
import { VisitsService } from '../../../shared/services/visits.service';
import { VisitFormDataErrorInterface } from '../../../shared/interfaces/visit-form-data-error.interface';
import { ClientWarehousesInterface } from '../../../shared/interfaces/client-warehouses.interface';
import { ClientAddressesInterface } from '../../../shared/interfaces/client-addresses.interface';
import {
  VisitsClient,
  VisitsClientWebapiInfoInterface,
} from '../../../shared/interfaces/visits-clients-graphql-response.interface';
import { VisitsGoalReachingsDataInterface } from '../../../shared/interfaces/visits-goal-reachings-data.interface';
import { GoalInput } from '../../../shared/interfaces/visits-mutations-response.interface';
import { VisitType } from '../../../shared/types/visit.type';
import { AppSessionService } from '../../../../../core/app-session-service/app-session.service';
import { VisitSetAddressInterface } from '../../../shared/interfaces/visit-set-address.interface';
import { VisitSetWarehouseInterface } from '../../../shared/interfaces/visit-set-warehouse.interface';

@Component({
  selector: 'app-visits-modal-start',
  templateUrl: './visits-modal-start.component.html',
  styleUrls: ['./visits-modal-start.component.scss'],
})
export class VisitsModalStartComponent implements DoCheck, OnInit {
  private percent: number = 0;
  public step: number = 1;
  private oldClientName: string = '';
  private oldDispatcherName: string = '';
  public isInternalError: boolean = false;

  public formData: VisitFormDataInterface = {
    visitType: '',
    deliveryTo: '',
    deliveryAddress: '',
    dispatcher: '',
    dispatcherAddress: '',
    visitGoal: '',
    visitComment: '',
  };

  public formDataErrors: VisitFormDataErrorInterface = {
    visitType: false,
    deliveryTo: false,
    deliveryAddress: false,
    dispatcher: false,
    dispatcherAddress: false,
    visitGoal: false,
    visitComment: false,
  };

  public formStepsFields: string[][] = [
    ['visitType', 'deliveryTo', 'deliveryAddress'],
    ['dispatcher', 'dispatcherAddress'],
    ['visitGoal', 'visitComment'],
  ];

  private client?: VisitsClientWebapiInfoInterface;

  private selectedAddressType?: 'none' | 'b2b' | 'b2c';
  public deliveryToOptions?: string[];
  public addressesNames?: string[];
  public companyNames?: string[];
  public companyAddresses?: string[];
  public goals?: string[];

  public clients?: VisitsClient[];
  public clientAddresses?: ClientAddressesInterface;
  public clientWarehouses?: ClientWarehousesInterface;
  public visitGoals?: VisitsGoalReachingsDataInterface[] | undefined;

  constructor(public visitsService: VisitsService, private sessionService: AppSessionService) {}

  async ngOnInit() {
    try {
      if (this.sessionService.sessionModal?.clientEmail) {
        this.client = await this.visitsService.getClientInfo(this.sessionService.sessionModal?.clientEmail);
      } else {
        this.formDataErrors['deliveryTo'] = 'Выбранного клиента не существует';
      }
    } catch (e: any) {
      if (e?.statusCode !== 200) {
        this.isInternalError = true;
      }
      this.formDataErrors['deliveryTo'] = 'Ошибка загрузки';
    }

    try {
      await this.getAddressesAndWarehousesByMail(this.client?.email || '');
      this.deliveryToOptions = this.getDeliveryToOptions();
      this.updateAddressesNames();
      this.updateCompaniesNames();
    } catch (e: any) {
      if (e?.statusCode !== 200) {
        this.isInternalError = true;
      }

      this.formDataErrors['deliveryTo'] = 'Ошибка загрузки';
      this.formDataErrors['deliveryAddress'] = 'Ошибка загрузки';
    }
  }

  async ngDoCheck() {
    let nameChanged = false;
    let dispatcherChanged = false;

    if (this.oldClientName !== this.formData['deliveryTo']) {
      this.oldClientName = this.formData['deliveryTo'];
      nameChanged = true;
    }

    if (this.oldDispatcherName !== this.formData['dispatcher']) {
      this.oldDispatcherName = this.formData['dispatcher'];
      dispatcherChanged = true;
    }

    if (nameChanged) {
      this.updateAddressesNames();
      this.updateCompaniesNames();
    }

    if (dispatcherChanged) {
      this.updateWarehousesNames();
    }

    if (!this.goals && this.visitsService.goalsAndReachings?.goals) {
      this.visitGoals = this.visitsService?.goalsAndReachings?.goals;
      this.goals = this.visitGoals?.map(goal => goal.title);
    }

    this.updatePercents();
  }

  private compareArrays(a1: any[], a2: any[]): boolean {
    return !a1.filter(i => !a2.includes(i)).concat(a2.filter(i => !a1.includes(i))).length;
  }

  private getDeliveryToOptions(): string[] {
    if (!this.clientAddresses) {
      return [];
    }

    const result: string[] = [];

    if (this.clientAddresses.b2b.length) {
      result.push(...this.clientAddresses.b2b.map(business => business.name));
    }

    if (this.clientAddresses.b2c.name) {
      result.push(this.clientAddresses.b2c.name);
    }

    if (!result.length) {
      this.formDataErrors['deliveryTo'] = 'Ошибка инициализации типов отгрузки';
    }
    return result;
  }

  private async getAddressesAndWarehousesByMail(email: string) {
    this.clientAddresses = await this.visitsService.getClientAddresses(email || '');
    this.clientWarehouses = await this.visitsService.getClientWarehouses(email || '');
  }

  private getAddressesWithDeliveryToValue(): [string[], 'b2b' | 'b2c' | 'none'] {
    const addresses: string[] = [];
    let type: 'b2b' | 'b2c' | 'none' = 'none';

    if (!this.formData['deliveryTo'] || !this.clientAddresses) {
      return [addresses, type];
    }

    const deliveryToName = this.formData['deliveryTo'];

    if (this.clientAddresses.b2c.name === deliveryToName) {
      addresses.push(...this.clientAddresses.b2c.addresses.map(addr => addr.city + ', ' + addr.address));
      type = 'b2c';
    } else if (this.clientAddresses.b2b.filter(business => business.name === deliveryToName).length) {
      addresses.push(
        ...this.clientAddresses.b2b
          .filter(business => business.name === deliveryToName)[0]
          .addresses.map(addr => addr.city + ', ' + addr.address),
      );
      type = 'b2b';
    }

    return [addresses, type];
  }

  private getSetAddressData(): VisitSetAddressInterface {
    const result: VisitSetAddressInterface = {
      deliveryTypeAlias: 'PICKUP',
      companyId: null,
      addressId: null,
      cityFilter: null,
    };
    if (!this.clientAddresses) {
      return result;
    }
    if (this.selectedAddressType === 'none') {
      return result;
    } else if (this.selectedAddressType === 'b2b') {
      const company = this.clientAddresses.b2b.filter(business => business.name === this.formData['deliveryTo'])[0];
      const address = company.addresses.filter(
        addr => addr.city + ', ' + addr.address === this.formData['deliveryAddress'],
      )[0];

      result.deliveryTypeAlias = 'COURIER_DELIVERY';
      result.companyId = company.id || null;
      result.addressId = address.id || null;
      result.cityFilter = address.city || null;
    } else if (this.selectedAddressType === 'b2c') {
      const address = this.clientAddresses.b2c.addresses.filter(
        addr => addr.city + ', ' + addr.address === this.formData['deliveryAddress'],
      )[0];
      result.deliveryTypeAlias = 'COURIER_DELIVERY';
      result.addressId = address.id || null;
      result.cityFilter = address.city || null;
    }

    return result;
  }

  private getSetWarehouseData(): VisitSetWarehouseInterface {
    const result: VisitSetWarehouseInterface = {
      warehouseId: null,
      networkId: null,
      isFavouriteShop: true,
      cityFilter: null,
    };

    if (!this.clientWarehouses) {
      return result;
    }

    const company = this.clientWarehouses.favouriteShops.filter(
      company => company.name === this.formData['dispatcher'],
    )[0];
    const warehouse = company.warehouses.filter(
      wh => wh.city + ', ' + wh.address === this.formData['dispatcherAddress'],
    )[0];

    if (company && warehouse) {
      result.warehouseId = warehouse.id;
      result.cityFilter = warehouse.city;
      result.networkId = company.id;
    }

    return result;
  }

  get progressPercent(): string {
    return Math.ceil(this.percent) + '%';
  }

  private setCompanyNames(tmpNewCompanies: string[] | undefined) {
    if (this.clientWarehouses?.selectedValues.seller?.id && !this.formData['dispatcher']) {
      const tmpCompany = this.clientWarehouses?.favouriteShops.filter(
        shop => shop.id === this.clientWarehouses?.selectedValues.seller.id,
      )[0];

      if (tmpCompany) {
        this.formData['dispatcher'] = tmpCompany.name;
        this.updateWarehousesNames();
      }
    }

    this.companyNames = tmpNewCompanies;
  }

  private setNewAddresses(tmpNewAddresses: string[], type: 'b2b' | 'b2c' | 'none') {
    const setFavouriteAddress = {
      b2b(ctx: VisitsModalStartComponent) {
        if (!tmpNewAddresses.length) {
          tmpNewAddresses = ['Самовывоз'];
          this.none(ctx);
        }

        ctx.addressesNames = tmpNewAddresses;
        ctx.formData['deliveryAddress'] = '';
        ctx.selectedAddressType = 'b2b';
      },
      b2c(ctx: VisitsModalStartComponent) {
        if (!tmpNewAddresses.length) {
          tmpNewAddresses = ['Самовывоз'];
          this.none(ctx);
        }

        if (!ctx.addressesNames || !ctx.compareArrays(tmpNewAddresses, ctx.addressesNames)) {
          ctx.addressesNames = tmpNewAddresses;
          const selectedClientAddresses = ctx.clientAddresses;

          if (selectedClientAddresses?.summaryData.selectedAddressId) {
            const selectedAddress = selectedClientAddresses.b2c.addresses.filter(
              addr => addr.id === selectedClientAddresses.summaryData.selectedAddressId,
            )[0];
            if (selectedAddress) {
              ctx.formData['deliveryAddress'] = selectedAddress.city + ', ' + selectedAddress.address;
            } else {
              ctx.formData['deliveryAddress'] = '';
            }
          } else {
            ctx.formData['deliveryAddress'] = '';
          }
          ctx.selectedAddressType = 'b2c';
        }
      },
      none(ctx: VisitsModalStartComponent) {
        ctx.addressesNames = tmpNewAddresses;
        ctx.formData['deliveryAddress'] = 'Самовывоз';
        ctx.selectedAddressType = 'none';
      },
    };

    setFavouriteAddress[type](this);
  }

  private startVisit() {
    const type: VisitType = this.formData['visitType'].replace('-', '').toLowerCase() as VisitType;
    const client_email: string = this.client?.email || '';
    const goalId = this.visitGoals?.filter(goal => goal.title === this.formData['visitGoal'])[0].id;
    const goal: GoalInput = {
      id: goalId ? +goalId : NaN,
      comment: this.formData['visitComment'],
    };
    return this.visitsService.openVisit(type, client_email, goal);
  }

  public async switchStep(isButtonNext: boolean) {
    if (isButtonNext && this.step < 3) {
      let errorsCount = 0;
      for (let field of this.formStepsFields[this.step - 1]) {
        if (!this.formData[field]) {
          if (!this.formDataErrors[field]) {
            this.formDataErrors[field] = true;
          }
          errorsCount++;
        } else {
          this.formDataErrors[field] = false;
        }
      }
      if (errorsCount) return;
      this.step++;
    } else if (isButtonNext && this.step === 3) {
      let errorsCount = 0;
      for (let field of this.formStepsFields[this.step - 1]) {
        if (!this.formData[field]) {
          this.formDataErrors[field] = true;
          errorsCount++;
        } else {
          this.formDataErrors[field] = false;
        }
      }
      if (errorsCount) return;
      if (this.sessionService.sessionGlobal) {
        try {
          await this.startVisit();
        } catch (e: any) {
          this.isInternalError = true;
        }
        await this.visitsService.setClientAddresses(this.getSetAddressData());
        await this.visitsService.setClientWarehouse(this.getSetWarehouseData());
        this.visitsService.closeModal(true);
      } else {
        throw new Error('[VISIT APP] GlobalSessionOptions Error. Cant create visit inside iframe.');
      }
      return;
    } else if (!isButtonNext && this.step > 1) {
      this.step--;
    } else {
      this.visitsService.closeModal();
      return;
    }
  }

  private updateAddressesNames() {
    if (!this.clientAddresses) {
      return;
    }

    const clientAddressesData = this.getAddressesWithDeliveryToValue();
    this.setNewAddresses(...clientAddressesData);

    if (this.formDataErrors['deliveryAddress']) {
      this.formDataErrors['deliveryAddress'] = '';
    }
  }

  private updateCompaniesNames() {
    if (!this.clientWarehouses) {
      return;
    }

    const tmpNewCompanies = this.clientWarehouses.favouriteShops.map(shop => shop.name);

    if (this.companyNames) {
      if (tmpNewCompanies[0] !== this.companyNames[0]) {
        this.formData['dispatcher'] = '';
      }
    }

    this.setCompanyNames(tmpNewCompanies);

    if (this.formDataErrors['dispatcher']) {
      this.formDataErrors['dispatcher'] = '';
    }
  }

  private updatePercents() {
    let percent = 0;
    for (let key in this.formData) {
      if (this.formData[key]) {
        percent += 14.24;
      }
    }
    this.percent = percent;
  }

  private updateWarehousesNames() {
    const tmpNewWarehouses = this.clientWarehouses?.favouriteShops?.filter(
      shop => shop.name === this.formData['dispatcher'],
    )[0].warehouses;

    if (!tmpNewWarehouses?.length) {
      this.companyAddresses = [];
      this.formData['dispatcherAddress'] = '';
      return;
    }

    if (
      this.companyAddresses &&
      tmpNewWarehouses[0].city + ', ' + tmpNewWarehouses[0].address !== this.companyAddresses[0]
    ) {
      this.formData['dispatcherAddress'] = '';
    } else if (
      this.companyAddresses &&
      tmpNewWarehouses[0].city + ', ' + tmpNewWarehouses[0].address === this.companyAddresses[0]
    ) {
      return;
    }

    if (this.clientWarehouses?.selectedValues.warehouse?.id && !this.formData['dispatcherAddress']) {
      const tmpWarehouse = tmpNewWarehouses?.filter(
        warehouse => warehouse.id === this.clientWarehouses?.selectedValues.warehouse.id,
      )[0];

      if (tmpWarehouse) {
        this.formData['dispatcherAddress'] = tmpWarehouse.city + ', ' + tmpWarehouse.address;
      }
    }
    this.companyAddresses = tmpNewWarehouses?.map(warehouse => warehouse.city + ', ' + warehouse.address);
  }
}
