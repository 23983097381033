import { Component } from '@angular/core';
import { VisitFormDataInterface } from '../../../shared/interfaces/visit-form-data.interface';
import { VisitFormDataErrorInterface } from '../../../shared/interfaces/visit-form-data-error.interface';
import { VisitsService } from '../../../shared/services/visits.service';
import { Router } from '@angular/router';
import { AppSessionService } from '../../../../../core/app-session-service/app-session.service';

@Component({
  selector: 'app-visits-modal-new',
  templateUrl: './visits-modal-new.component.html',
  styleUrls: ['./visits-modal-new.component.scss'],
})
export class VisitsModalNewComponent {
  public isInternalError: boolean = false;
  public formData: VisitFormDataInterface = {
    email: '',
    name: '',
    country: 'Функция в разработке',
    city: 'Функция в разработке',
    dispatcherAddress: 'Функция в разработке',
    type: 'Функция в разработке',
  };

  public formDataErrors: VisitFormDataErrorInterface = {
    email: false,
    name: false,
    country: false,
    city: false,
    dispatcherAddress: false,
    type: false,
  };

  public formStepsFields: string[] = Object.keys(this.formData);

  constructor(public visitsService: VisitsService, private router: Router, private sessionService: AppSessionService) {}

  public async onChangeVerifyEmail(): Promise<boolean> {
    try {
      const clientInfo = await this.visitsService.getClientInfo(this.formData['email']);
      if (clientInfo?.email) {
        this.formDataErrors['email'] = 'Пользователь с таким email уже зарегистрирован';
        return false;
      }
      this.formDataErrors['email'] = false;
      return true;
    } catch (e: any) {
      if (e?.statusCode !== 200 && !e?.message) {
        this.isInternalError = true;
        return false;
      }

      if (e.message) {
        this.formDataErrors['email'] = e.message;
        return false;
      }

      this.formDataErrors['email'] = 'Введите действительную почту';
      return false;
    }
  }

  public async continueCreateClient() {
    let errorsCount = 0;

    for (let field of this.formStepsFields) {
      if (!this.formData[field]) {
        this.formDataErrors[field] = 'Заполните поле';
        errorsCount++;
      } else {
        this.formDataErrors[field] = false;
      }
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.formData['email'])) {
      errorsCount += 1;
      this.formDataErrors['email'] = 'Введите действительную почту';
    } else if (!(await this.onChangeVerifyEmail())) {
      errorsCount += 1;
    }

    if (errorsCount) return;
    try {
      await this.visitsService.createNewClient(this.formData['email'], this.formData['name']);
      await this.visitsService.createClient(this.formData['email']);
    } catch (e: any) {
      if (e?.statusCode !== 200 || e?.statusCode !== 400) {
        this.isInternalError = true;
      }
      return;
    }

    if (this.sessionService.sessionModal) {
      this.sessionService.sessionModal['clientEmail'] = this.formData['email'];
    } else {
      throw new Error('[VISIT APP] ModalSession data was lost');
    }

    await this.router.navigate(['/', 'visits', 'modal', 'start']);
  }
}
