import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateStringToDate',
})
export class DateStringToDatePipe implements PipeTransform {
  transform(value: string): Date {
    return new Date(value);
  }
}
