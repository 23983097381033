import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { VisitsService } from '../../../shared/services/visits.service';
import { VisitsClient } from '../../../shared/interfaces/visits-clients-graphql-response.interface';
import { AppSessionService } from '../../../../../core/app-session-service/app-session.service';

@Component({
  selector: 'app-visits-express-panel-my',
  templateUrl: './visits-express-panel-my.component.html',
  styleUrls: ['./visits-express-panel-my.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisitsExpressPanelMyComponent implements AfterViewInit, OnInit, AfterViewChecked {
  public filteredClients: VisitsClient[];
  public selectedClient?: VisitsClient;
  private oneLetterWidth: number = 9;
  public filterText: string = '';
  private resizeTimeoutId?: NodeJS.Timeout;
  @Input() public isPopup?: boolean;

  public clientsData!: VisitsClient[];
  @ViewChild('clientsList') clientsListElement?: ElementRef;

  constructor(
    public visitsService: VisitsService,
    private sessionService: AppSessionService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.filteredClients = [];
  }

  /*
    Getting window size for updating long text to 'some text...'
    and detecting mobile size for rebuild template for mobile
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    const windowRef: Window = event.currentTarget as Window;
    this.visitsService.isMobile = windowRef.innerWidth < 375;
    this.visitsService.innerHeight = window.innerHeight;

    clearTimeout(this.resizeTimeoutId);
    this.resizeTimeoutId = setTimeout(() => {
      this.updateListItemsText();
    }, 100);
  }

  async ngOnInit() {
    // Such as onWindowResize but triggered with initializing component
    const rawClientsData = await this.visitsService.getClientsData();
    this.clientsData = new Array(...rawClientsData);
    this.visitsService.isMobile = window.innerWidth < 375;
    if (this.clientsData.length) {
      this.selectedClient = this.clientsData[0];
    }
    this.updateFilteredVisitsArray();
  }

  ngAfterViewInit() {
    if (this.clientsListElement) {
      this.updateListItemsText();
    }
  }

  ngAfterViewChecked(): void {
    if (this.visitsService.isMobile) {
      document.querySelector('.list-item-wrapper.active')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  // buttons actions
  public createNewClient() {
    this.sessionService.sendResponseToIframe(true, 'new');
  }

  public startVisit() {
    this.sessionService.sendResponseToIframe(true, 'start', `${this.selectedClient?.email}`);
  }

  // Filter clients array with input text
  public filterClients(): void {
    if (this.filterText.length) {
      this.filteredClients = this.clientsData?.filter(client => {
        return client.name.toLowerCase().includes(this.filterText.toLowerCase());
      });
    } else {
      this.filteredClients = this.clientsData;
    }
  }

  public getMaxOneLineTextLength(): number {
    if (this.clientsListElement) {
      return Math.ceil(this.clientsListElement.nativeElement.clientWidth / this.oneLetterWidth);
    }
    return 0;
  }

  public isClientSelected(client: VisitsClient): boolean {
    return this.selectedClient?.name === client.name && this.selectedClient.email === client.email;
  }

  public selectClient(client: VisitsClient): void {
    this.selectedClient = Object.assign(
      {},
      this.clientsData.find(el => {
        return el.name === client.name && client.email === el.email;
      }),
    );
  }

  private updateFilteredVisitsArray() {
    const clientsRaw = this.clientsData?.map(client => {
      return Object.assign({}, client);
    });
    if (clientsRaw) {
      this.filteredClients = new Array(...clientsRaw);
    } else {
      this.filteredClients = [];
    }
    this.changeDetectorRef.detectChanges();
  }

  private updateListItemsText() {
    if (this.visitsService.isMobile) {
      return;
    }

    this.updateFilteredVisitsArray();
    this.filteredClients = new Array(
      ...this.filteredClients.map(client => {
        client.name = client.name;
        return client;
      }),
    );
  }
}
