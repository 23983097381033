import { Component, DoCheck, OnInit } from '@angular/core';
import { VisitsService } from '../../shared/services/visits.service';
import { Router } from '@angular/router';
import { AppSessionService } from '../../../../core/app-session-service/app-session.service';

@Component({
  selector: 'app-visits-express-panel',
  templateUrl: './visits-express-panel.component.html',
  styleUrls: ['./visits-express-panel.component.scss'],
})
export class VisitsExpressPanelComponent implements OnInit, DoCheck {
  public currentPath: string = '';
  constructor(public visitsService: VisitsService, private router: Router, private sessionService: AppSessionService) {}

  ngOnInit() {
    this.visitsService.innerHeight = window.innerHeight;
    this.visitsService.innerWidth = window.innerWidth;

    const rawUrl = this.router.url.split('/');
    if (rawUrl.length < 4) {
      this.currentPath = '';
    } else if (rawUrl.length > 4) {
      this.currentPath = 'list';
    } else {
      this.currentPath = rawUrl[3];
    }
  }

  ngDoCheck() {
    if (this.isNeedToShowOpenedVisit) {
      this.switchPanelContentPage('');
    }
  }

  private get isNeedToShowOpenedVisit(): boolean {
    return !!this.sessionService.sessionGlobal?.visitId && !this.currentPath.includes('list/') && !this.currentPath;
  }

  public switchPanelContentPage(path: string): void {
    const pathArray: string[] = ['/', 'visits', 'express-panel'];
    if (this.sessionService.sessionGlobal?.visitId) {
      pathArray.push('list', '' + this.sessionService.sessionGlobal.visitId);
      path = 'list/' + this.sessionService.sessionGlobal.visitId;
    } else if (path.length) {
      pathArray.push(path);
    }
    this.router.navigate(pathArray);
    this.currentPath = path;
    console.log(this.currentPath);
  }
}
