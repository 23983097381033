import { Component, Input } from '@angular/core';
import { VisitFormDataInterface } from '../../../shared/interfaces/visit-form-data.interface';

@Component({
  selector: 'app-visits-modal-form-selector',
  templateUrl: './visits-modal-form-selector.component.html',
  styleUrls: ['./visits-modal-form-selector.component.scss'],
})
export class VisitsModalFormSelectorComponent {
  @Input() public formData!: VisitFormDataInterface;
  @Input() public formDataField!: string;
  @Input() public title!: string;
  @Input() public values!: string[];
  @Input() public isError!: boolean | string;
  @Input() public isBlocked?: boolean;

  public isOpenedSpoiler: boolean = false;

  constructor() {}

  public selectSpoilerElement(event: MouseEvent, fieldValue: string, field: string) {
    this.formData[field] = fieldValue;
    this.switchSpoilerStatus(event);
  }

  public switchSpoilerStatus(event: MouseEvent) {
    if (this.isBlocked) {
      return;
    }

    event.preventDefault();

    this.isOpenedSpoiler = !this.isOpenedSpoiler;
  }

  public get errorText(): string {
    return typeof this.isError === 'string' ? this.isError : 'Выберите необходимое значение';
  }
}
