import { Component, OnInit } from '@angular/core';
import { VisitFormDataInterface } from '../../../shared/interfaces/visit-form-data.interface';
import { VisitFormDataErrorInterface } from '../../../shared/interfaces/visit-form-data-error.interface';
import { VisitsService } from '../../../shared/services/visits.service';
import { AppSessionService } from '../../../../../core/app-session-service/app-session.service';

@Component({
  selector: 'app-visits-modal-end-complete',
  templateUrl: './visits-modal-end-complete.component.html',
  styleUrls: ['./visits-modal-end-complete.component.scss'],
})
export class VisitsModalEndCompleteComponent implements OnInit {
  public isInternalError: boolean = false;
  public step: number = 1;
  public formData: VisitFormDataInterface = {
    visitGoal: '',
    comment: '',
  };
  public formDataErrors: VisitFormDataErrorInterface = {
    visitGoal: false,
    comment: false,
  };
  public formStepsFields: string[] = Object.keys(this.formData);

  constructor(private visitsService: VisitsService, private sessionService: AppSessionService) {}

  async ngOnInit() {
    if (!this.visitsService.goalsAndReachings) {
      try {
        await this.visitsService.getGoalsAndReachings();
      } catch (e: any) {
        if (e?.statusCode !== 200) {
          this.isInternalError = true;
        }
      }
    }
  }

  public async switchStep(event: Event) {
    event.stopPropagation();
    if (this.step == 2) {
      let errorsCount = 0;
      for (let field of this.formStepsFields) {
        if (!this.formData[field]) {
          this.formDataErrors[field] = true;
          errorsCount++;
        } else {
          this.formDataErrors[field] = false;
        }
      }
      if (errorsCount) return;

      try {
        await this.closeVisit();
        this.step++;
      } catch (e: any) {
        if (e?.statusCode !== 200) {
          this.isInternalError = true;
        }
      }
    } else if (this.step !== 3) {
      this.step++;
    } else {
      this.visitsService.closeModal(true);
    }
  }

  get reachings(): string[] {
    if (this.visitsService.goalsAndReachings?.reachings) {
      return this.visitsService.goalsAndReachings?.reachings.map(reaching => {
        return reaching.title;
      });
    } else {
      return [''];
    }
  }

  get completeDate(): string {
    const closeDate = new Date();
    return `${closeDate.getHours()}:${closeDate.getMinutes()}, ${
      closeDate.getMonth() + 1
    }-${closeDate.getDate()}-${closeDate.getFullYear()}`;
  }

  private async closeVisit() {
    if (this.sessionService.sessionGlobal?.visitId) {
      await this.visitsService.closeVisit(
        +this.sessionService.sessionGlobal?.visitId,
        Number.parseInt(
          `${
            this.visitsService.goalsAndReachings?.reachings.filter(
              reaching => reaching.title === this.formData['visitGoal'],
            )[0]?.id
          }`,
        ),
        this.formData['comment'] as string,
      );

      if (this.sessionService.sessionModal) {
        this.sessionService.sendResponseToIframe(
          false,
          null,
          '',
          this.sessionService.sessionModal?.lastIframeId,
          NaN,
          true,
        );
      } else {
        throw new Error('[VISIT APP] ModalSessionOptions Error. Modal options should be initialized.');
      }
    } else {
      throw new Error('[VISIT APP] GlobalSessionOptions Error. This options should be initialized');
    }
  }
}
