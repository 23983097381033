import { Component } from '@angular/core';
import { VisitsService } from '../../../shared/services/visits.service';
import { AppSessionService } from '../../../../../core/app-session-service/app-session.service';

@Component({
  selector: 'app-visits-modal-delete',
  templateUrl: './visits-modal-delete.component.html',
  styleUrls: ['./visits-modal-delete.component.scss'],
})
export class VisitsModalDeleteComponent {
  constructor(public visitsService: VisitsService, private sessionService: AppSessionService) {}

  public async deleteDraftedVisit() {
    if (this.sessionService.sessionGlobal?.visitId) {
      await this.visitsService.deleteDrafted(+this.sessionService.sessionGlobal?.visitId);
      this.sessionService.sendResponseToIframe(
        false,
        null,
        '',
        this.sessionService.sessionModal?.lastIframeId,
        NaN,
        true,
      );
    } else {
      throw new Error('[VISIT APP] Session global aint initialized.');
    }
  }
}
