<div class="field-selector">
  <div class="field-selector-title">
    {{ title }}
  </div>
  <div class="field-selector-spoiler-wrapper">
    <button
            class="field-selector-spoiler-button"
            [class.active]="isOpenedSpoiler"
            [class.blocked]="isBlocked"
            (click)="switchSpoilerStatus($event)"
    >
      {{ formData[formDataField] ? (formData[formDataField] | stringCutWithDots : 33) : title }}
      <img src="../../../../../../assets/images/icons/arrow-down.svg" alt="стрелка спойлера формы">
    </button>
    <div
      class="spoiler-detect-click-area"
       (click)="switchSpoilerStatus($event)"
       [hidden]="!isOpenedSpoiler"
    ></div>
    <div class="field-selector-spoiler" [class.hidden]="!isOpenedSpoiler">
      <div
        class="field-selector-spoiler-item"
        *ngFor="let value of values"
        [class.selected]="value === formData[formDataField]"
        (click)="selectSpoilerElement($event, value, formDataField)"
      >
        {{ value }}
      </div>
    </div>
    <div class="modal-form-hint" [class.hidden]="!(!formData[formDataField] && isError)">{{ errorText }}</div>
  </div>
</div>
