<!--<div *ngIf="sessionService.sessionGlobal else loading" class="loading">-->
<!--  <router-outlet></router-outlet>-->
<!--</div>-->
<div *ngIf="sessionService.sessionGlobal?.bearerKey && authorisationService.authorizeStatus === 'authorized' else loading" class="loading">
  <router-outlet></router-outlet>
</div>

<ng-template #loading>
  <div
    *ngIf="authorisationService.authorizeStatus === 'loading' else error"
    class="loading spinner"
  >
    <div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-template>

<ng-template #error>
  <div
    *ngIf="!sessionService.sessionGlobal?.bearerKey || authorisationService.authorizeStatus === 'error'"
    class="loading error"
  >Ошибка авторизации</div>
</ng-template>
