import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringCutWithDots',
})
export class StringCutWithDotsPipe implements PipeTransform {
  transform(value: string, length: number): string {
    if (value.length < length) {
      return value;
    }
    return value.substring(0, length - 3) + '...';
  }
}
