import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TypedDocumentNode } from 'apollo-angular';
import { lastValueFrom, Observable, of, switchMap, throwError } from 'rxjs';
import { GraphqlMutationInterface, GraphqlVariablesInterface } from '../interfaces/graphql-api.interface';
import { ApolloClient, ApolloQueryResult } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { GraphQLAPIDomain } from '../../../../app-export.constant';
import { AppSessionService } from '../../../app-session-service/app-session.service';
import { HttpLink } from 'apollo-angular/http';

@Injectable()
export class GraphqlApiService {
  constructor(private apollo: Apollo, private appSessionService: AppSessionService, private httpLink: HttpLink) {}

  public query<T>(query: TypedDocumentNode, variables?: GraphqlVariablesInterface): Promise<T> {
    if (this.appSessionService.sessionGlobal?.bearerKey) {
      this.updateClient();
    }
    return this.queryResolve(this.apollo.query<T>({ query, variables, errorPolicy: 'ignore' }));
  }

  private queryResolve<T>(observable: Observable<ApolloQueryResult<T>>): Promise<T> {
    return lastValueFrom(
      observable.pipe(
        switchMap(response => {
          if (!response.data) {
            return throwError(() => 'GraphQL API response null it might be error');
          }
          return of(response.data);
        }),
      ),
    );
  }

  public async mutate<T>(mutation: TypedDocumentNode, variables?: GraphqlVariablesInterface) {
    if (this.appSessionService.sessionGlobal?.bearerKey) {
      this.updateClient();
    }
    return this.mutateResolve(
      this.apollo.mutate<T>({ mutation, variables, errorPolicy: 'ignore' }) as Observable<GraphqlMutationInterface<T>>,
    );
  }

  private mutateResolve<T>(observable: Observable<GraphqlMutationInterface<T>>) {
    return lastValueFrom(
      observable.pipe(
        switchMap(response => {
          if (response?.data) {
            return of(response.data);
          } else {
            return throwError(() => 'GraphQL API response null it might be error');
          }
        }),
      ),
    );
  }

  private updateClient() {
    const basic = setContext((operation, context) => {
      return {
        headers: {
          Accept: 'charset=base64',
        },
      };
    });
    const auth = setContext((operation, context) => {
      return {
        headers: {
          Authorization: `Bearer ${this.appSessionService.sessionGlobal?.bearerKey}`,
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(value => {
          console.log(`[GraphQL API Error]: Message: ${value.message}, Path: ${value.path}`);
          console.log(value);
        });
      if (networkError) console.log(`[Network error]`, networkError);
    });

    const rawHttpLink = ApolloLink.from([basic, auth, this.httpLink.create({ uri: GraphQLAPIDomain })]);
    const link = errorLink.concat(rawHttpLink);
    const cache = new InMemoryCache();
    this.apollo.removeClient();
    this.apollo.client = new ApolloClient<any>({ link, cache });
  }
}
