import { environment } from '../environments/environment';

export const WebAPIDomain = environment.magicApiDomain;

export const GraphQLAPIDomain = environment.visitApiDomain + 'graphql';

export type ModuleRoutes = {
  [key: string]: string[];
};

export const ModuleRoutes: ModuleRoutes = {
  'express-panel': ['visits', 'express-panel'],
  modal: ['visits', 'modal'],
  popup: ['visits', 'popup'],
};
