import { Component, OnInit } from '@angular/core';
import { AppSessionService } from '../../../../../core/app-session-service/app-session.service';
import { VisitsService } from '../../../shared/services/visits.service';
import { VisitInfoInterface } from '../../../shared/interfaces/visit-info.interface';
import { VisitsClientWebapiInfoInterface } from '../../../shared/interfaces/visits-clients-graphql-response.interface';

@Component({
  selector: 'app-visits-popup-exit',
  templateUrl: './visits-popup-exit.component.html',
  styleUrls: ['./visits-popup-exit.component.scss'],
})
export class VisitsPopupExitComponent implements OnInit {
  public visitData: VisitInfoInterface | undefined;
  public clientInfo: VisitsClientWebapiInfoInterface | undefined;

  constructor(private sessionService: AppSessionService, private visitsService: VisitsService) {}

  async ngOnInit() {
    const visitInfo = await this.visitsService.getOpenedVisit();
    this.sessionService.currentVisitID = visitInfo ? +visitInfo.id : visitInfo;
    if (this.sessionService.sessionGlobal?.visitId) {
      this.visitData = await this.visitsService.getVisitDetails(this.sessionService.sessionGlobal.visitId);
      this.clientInfo = await this.visitsService.getClientInfo(this.visitData.client.email);
    } else {
      this.clientInfo = {
        name: 'Ошибка загрузки данных',
      } as VisitsClientWebapiInfoInterface;
      throw new Error('[VISIT APP] GlobalSessionOptions Error. Current visit is lost.');
    }
  }

  public closeVisit() {
    if (this.sessionService.sessionGlobal?.visitId) {
      this.sessionService.sendResponseToIframe(true, 'end/complete');
    } else {
      throw new Error('[VISIT APP] GlobalSessionOptions Error. Current visit is lost.');
    }
  }
}
