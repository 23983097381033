import { Component } from '@angular/core';
import { VisitsService } from '../../../shared/services/visits.service';
import { AppSessionService } from '../../../../../core/app-session-service/app-session.service';

@Component({
  selector: 'app-visits-modal-end-save',
  templateUrl: './visits-modal-end-save.component.html',
  styleUrls: ['./visits-modal-end-save.component.scss'],
})
export class VisitsModalEndSaveComponent {
  public isInternalError: boolean = false;

  constructor(public visitsService: VisitsService, private sessionService: AppSessionService) {}

  public async saveVisitToDrafts() {
    if (this.sessionService.sessionGlobal?.visitId) {
      try {
        await this.visitsService.draftVisit(+this.sessionService.sessionGlobal?.visitId);
        await this.visitsService.logout();

        if (this.sessionService.sessionModal) {
          this.sessionService.sendResponseToIframe(
            false,
            null,
            '',
            this.sessionService.sessionModal?.lastIframeId,
            NaN,
            true,
          );
        } else {
          throw new Error('[VISIT APP] ModalSessionOptions Error. Modal options should be initialized with iframe.');
        }
      } catch (e: any) {
        if (e?.statusCode !== 200) {
          this.isInternalError = true;
        }
      }
    } else {
      throw new Error('[VISIT APP] GlobalSessionOptions Error. Current visit is lost.');
    }
  }
}
