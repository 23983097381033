import { Component } from '@angular/core';
import { AppSessionService } from '../../../../../core/app-session-service/app-session.service';

@Component({
  selector: 'app-visits-popup-exit-account',
  templateUrl: './visits-popup-exit-account.component.html',
  styleUrls: ['./visits-popup-exit-account.component.scss'],
})
export class VisitsPopupExitAccountComponent {
  constructor(private sessionService: AppSessionService) {}

  public closeVisit() {
    if (this.sessionService.sessionGlobal?.visitId) {
      this.sessionService.sendResponseToIframe(true, 'end/complete');
    } else {
      throw new Error('[VISIT APP] GlobalSessionOptions Error. Current visit is lost.');
    }
  }

  public saveDraft() {
    if (this.sessionService.sessionGlobal?.visitId) {
      this.sessionService.sendResponseToIframe(true, 'end/save');
    } else {
      throw new Error('[VISIT APP] GlobalSessionOptions Error. Current visit is lost.');
    }
  }
}
