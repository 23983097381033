<div class="visits-modal-delete-wrapper">
  <h1 class="visits-modal-delete-header">Удаление черновика</h1>
  <h2 class="visits-modal-delete-info">
    Вы хотите удалить черновик<br>
    и продолжить работу?
  </h2>
  <div class="visits-modal-delete-actions">
    <button class="visits-modal-delete-action-no" (click)="visitsService.closeModal()">Отмена</button>
    <button class="visits-modal-delete-action-save" (click)="deleteDraftedVisit()">Удалить черновик</button>
  </div>
</div>
