<div *ngIf="visitsService.innerWidth >= 540 else mobileVersion" class="visits-table-wrapper">
  <div *ngIf="tableData" class="table-wrapper" [style]="{ 'grid-template-columns': gridTemplateColumns, width: '100%'}">
    <div
      class="visits-table-content header"
       *ngFor="let title of tableData.titles; let i = index"
       [class.first]="i === 0"
       [class.last]="i === tableData.titles.length - 1"
       [innerHTML]="title"
    >
    </div>
    <ng-container *ngFor="let row of tableData?.rows">
      <ng-container *ngFor="let cell of row?.rowData; let i = index">
        <ng-container
          *ngTemplateOutlet="cell.link?.linkSrc ? tableContentLink : tableContentText;
          context: { $implicit: getNewObjectToCellTemplate(i, cell) }">
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>


<ng-template #mobileVersion>
  <div *ngIf="tableData" class="mobile-content-wrapper">
    <a
      class="mobile-cell"
      *ngFor="let row of tableData.rows"
      [routerLink]="row.rowData[row.rowData.length-1]?.link?.linkSrc || ['/']"
    >
      <div class="mobile-cell-row" *ngFor="let title of tableData.titles.slice(0, 5); let i = index">
        <div class="mobile-cell-title">{{ title }}</div>
        <div class="mobile-cell-data">
          {{
             row.rowData[i]['text']
          }}
        </div>
      </div>
    </a>
  </div>
</ng-template>

<ng-template #tableContentLink let-cell let-index>
  <a
    [routerLink]="cell['link']['linkSrc']"
    class="visits-table-content"
    [class.first]="cell['index'] === 0"
    [class.last]="cell['index'] === tableData?.titles?.length ? tableData?.titles?.length : 0"
  >
    {{ cell['text'] }}
  </a>
</ng-template>
<ng-template #tableContentText let-cell let-index>
  <div
    class="visits-table-content"
    [class.first]="cell['index'] === 0"
    [class.last]="cell['index'] === tableData?.titles?.length ? tableData?.titles?.length : 0"
    [innerHTML]="cell['text'].includes('</br>') ? cell['text'] :
          cell['text'] | stringCutWithDots : neededTextLength"
  >
  </div>
</ng-template>
