import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VisitInfoInterface } from '../../../shared/interfaces/visit-info.interface';
import { VisitsService } from '../../../shared/services/visits.service';
import { AppSessionService } from '../../../../../core/app-session-service/app-session.service';
import { VisitOrderInfoInterface } from '../../../shared/interfaces/visit-order-info.interface';
import { VisitsClientWebapiInfoInterface } from '../../../shared/interfaces/visits-clients-graphql-response.interface';

@Component({
  selector: 'app-visits-list-details',
  templateUrl: './visits-list-details.component.html',
  styleUrls: ['./visits-list-details.component.scss'],
})
export class VisitsListDetailsComponent implements OnInit {
  private id: number = NaN;
  public visitDetails: VisitInfoInterface | undefined;
  public visitOrders: VisitOrderInfoInterface[] = [];
  public visitClientInfo?: VisitsClientWebapiInfoInterface;

  constructor(
    private route: ActivatedRoute,
    public visitsService: VisitsService,
    private sessionService: AppSessionService,
  ) {}

  async ngOnInit() {
    this.visitsService.isMobile = window.innerWidth < 375;
    this.route.params.subscribe(async params => {
      this.id = +params['id'];
      this.visitDetails = await this.visitsService.getVisitDetails(this.id);
      this.visitClientInfo = await this.visitsService.getClientInfo(this.visitDetails.client.email);
      if (this.visitDetails.orders.length) {
        this.visitOrders = await this.visitsService.getOrderBasicInfo(
          this.visitDetails.orders.map(order => order.order_id),
        );
      }
    });
  }

  public getCapitalizedFirstLetterWord(str: string | undefined) {
    if (!str) {
      return '';
    }
    return str.charAt(0)?.toUpperCase() + str.substring(1);
  }

  public closeVisit() {
    this.sessionService.sendResponseToIframe(true, 'end/complete');
  }
}
