import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitsExpressPanelComponent } from './pages/visits-express-panel/visits-express-panel.component';
import { VisitsLayoutComponent } from './pages/visits-layout/visits-layout.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { VisitsExpressPanelMyComponent } from './components/visits-express-panel-my/visits-express-panel-my/visits-express-panel-my.component';
import { VisitsService } from './shared/services/visits.service';
import { VisitsExpressPanelListComponent } from './components/visits-express-panel-my/visits-list/visits-express-panel-list.component';
import { VisitsTableComponent } from './components/visits-table/visits-table.component';
import { VisitsModalComponent } from './pages/visits-modal/visits-modal.component';
import { VisitsModalNewComponent } from './components/visits-modal/visits-modal-new/visits-modal-new.component';
import { VisitsModalStartComponent } from './components/visits-modal/visits-modal-start/visits-modal-start.component';
import { VisitsModalEndComponent } from './components/visits-modal/visits-modal-end/visits-modal-end.component';
import { VisitsModalFormRadioComponent } from './components/visits-modal-forms/visits-modal-form-radio/visits-modal-form-radio.component';
import { VisitsModalFormSelectorComponent } from './components/visits-modal-forms/visits-modal-form-selector/visits-modal-form-selector.component';
import { VisitsModalFormInputComponent } from './components/visits-modal-forms/visits-modal-form-input/visits-modal-form-input.component';
import { VisitsModalEndCompleteComponent } from './components/visits-modal/visits-modal-end-complite/visits-modal-end-complete.component';
import { VisitsModalEndSaveComponent } from './components/visits-modal/visits-modal-end-save/visits-modal-end-save.component';
import { VisitsModalDeleteComponent } from './components/visits-modal/visits-modal-delete/visits-modal-delete.component';
import { VisitsPopupComponent } from './pages/visits-popup/visits-popup.component';
import { VisitsPopupExitComponent } from './components/visits-popup/visits-popup-exit/visits-popup-exit.component';
import { VisitsPopupExitAccountComponent } from './components/visits-popup/visits-popup-exit-account/visits-popup-exit-account.component';
import { VisitsPopupMyComponent } from './components/visits-popup/visits-popup-my/visits-popup-my.component';
import { VisitsApiService } from './shared/services/visits-api.service';
import { VisitsListDetailsComponent } from './components/visits-express-panel-my/visits-list-details/visits-list-details.component';
import { VisitsDraftsComponent } from './components/visits-express-panel-my/visits-drafts/visits-drafts.component';
import { DateStringToDatePipe } from './shared/pipes/date-string-to-date.pipe';
import { VisitsModalContinueComponent } from './components/visits-modal/visits-modal-continue/visits-modal-continue.component';
import { StringCutWithDotsPipe } from './shared/pipes/string-cut.pipe';

@NgModule({
  declarations: [
    VisitsLayoutComponent,
    VisitsExpressPanelComponent,
    VisitsExpressPanelMyComponent,
    VisitsExpressPanelListComponent,
    VisitsTableComponent,
    VisitsModalComponent,
    VisitsModalNewComponent,
    VisitsModalStartComponent,
    VisitsModalEndComponent,
    VisitsModalFormRadioComponent,
    VisitsModalFormSelectorComponent,
    VisitsModalFormInputComponent,
    VisitsModalEndCompleteComponent,
    VisitsModalEndSaveComponent,
    VisitsModalDeleteComponent,
    VisitsPopupComponent,
    VisitsPopupExitComponent,
    VisitsPopupExitAccountComponent,
    VisitsPopupMyComponent,
    VisitsListDetailsComponent,
    VisitsDraftsComponent,
    DateStringToDatePipe,
    StringCutWithDotsPipe,
    VisitsModalContinueComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule],
  providers: [VisitsService, VisitsApiService, DateStringToDatePipe],
})
export class VisitsModule {}
