import { gql, TypedDocumentNode } from 'apollo-angular';

export const getGoals: TypedDocumentNode = gql`
  query getGoals {
    getGoals {
      id
      title
      code
    }
  }
`;

export const getReachings: TypedDocumentNode = gql`
  query getReachings {
    getReachings {
      id
      title
      code
    }
  }
`;

export const getClient: TypedDocumentNode = gql`
  query getClient($id: Int!) {
    getClient(id: $id) {
      id
      email
      manager_id
    }
  }
`;

export const getClientsInfo: TypedDocumentNode = gql`
  query getClientsInfo {
    getClientsInfo {
      client_email
      visits_count
      orders_count
      last_visit
    }
  }
`;

export const getVisits: TypedDocumentNode = gql`
  query getVisits {
    getVisits {
      id
      visit_number
      client_email
      created_at
      time_start
      time_end
    }
  }
`;

export const getVisitDetail: TypedDocumentNode = gql`
  query getVisitDetail($id: Int!) {
    getVisitDetail(id: $id) {
      id
      number
      manager_id
      client_id
      time_start
      time_end
      latitude
      longitude
      status
      type
      goal_id
      goal_comment
      reaching_id
      reaching_comment
      orders {
        visit_id
        order_id
      }
      client {
        email
      }
    }
  }
`;

export const getOpenedVisit: TypedDocumentNode = gql`
  query getOpenedVisit {
    getOpenedVisit {
      id
      number
      manager_id
      client_id
      time_start
      time_end
      latitude
      longitude
      status
      type
      goal_id
      goal_comment
      reaching_id
      reaching_comment
      orders {
        visit_id
        order_id
      }
      client {
        email
      }
    }
  }
`;

export const getDraftedVisits: TypedDocumentNode = gql`
  query getDraftedVisits {
    getDraftedVisits {
      id
      created_at
      time_draft
      client_email
    }
  }
`;
