<div [ngSwitch]="step" class="visits-modal-end-complete">
  <ng-container *ngSwitchCase="1">
    <ng-content *ngTemplateOutlet="firstStep"></ng-content>
  </ng-container>
  <ng-container *ngSwitchCase="2">
    <ng-content *ngTemplateOutlet="secondStep"></ng-content>
  </ng-container>
  <ng-container *ngSwitchCase="3">
    <ng-content *ngTemplateOutlet="thirdStep"></ng-content>
  </ng-container>
</div>

<ng-template #firstStep>
  <div class="end-complete-header">
    Сейчас открыт визит без подключения к аккаунту
  </div>
  <div class="end-complete-subtitle">
    Вы хотите завершить визит и продолжить работу?
  </div>
  <button class="end-complete-action-btn extended" (click)="switchStep($event)">Завершить визит</button>
</ng-template>

<ng-template #secondStep>
  <div class="end-complete-header forms-block">
    Цель визита была достигнута?
  </div>
  <form>
    <app-visits-modal-form-selector
      [formData]="formData"
      formDataField="visitGoal"
      title="Выберите вариант ответа"
      [values]="reachings"
      [isError]="formDataErrors['visitGoal']"
    >
    </app-visits-modal-form-selector>
    <app-visits-modal-form-input
      [formData]="formData"
      formDataField="comment"
      title="Комментарий"
      [isError]="formDataErrors['comment']"
    >
    </app-visits-modal-form-input>
  </form>
  <div [hidden]="!isInternalError" class="modal-internal-error">
    Мы сожалеем, произошел сбой в системе<br>
    Попробуйте еще раз спустя некоторое время
  </div>
  <button class="end-complete-action-btn" (click)="switchStep($event)">Завершить визит</button>
</ng-template>

<ng-template #thirdStep>
  <div class="end-complete-header">
    Визит успешно завершён!
  </div>
  <div class="end-complete-info">
    <div class="end-complete-info-done"></div>
    <div class="end-complete-info-text">
      Время завершения:<br>
      <span>{{ completeDate }}</span>
    </div>
  </div>
  <button class="end-complete-action-btn extended" (click)="switchStep($event)">Завершить визит</button>
</ng-template>
