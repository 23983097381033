import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WebApiInterface } from '../interfaces/web-api.interface';
import { lastValueFrom, Observable, of, switchMap, throwError } from 'rxjs';
import { WebAPIDomain } from '../../../../app-export.constant';
import { CookieService } from '@gorniv/ngx-universal';
import { AppSessionService } from '../../../app-session-service/app-session.service';

@Injectable()
export class WebApiService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private sessionService: AppSessionService,
  ) {}

  public get<T>(path: string, options?: Parameters<HttpClient['get']>[1], isEmptyResponse?: boolean): Promise<T>;

  public get<T>(
    path: string,
    options?: Parameters<HttpClient['get']>[1],
    isEmptyResponse?: boolean,
  ): Promise<T | WebApiInterface<T>> {
    return this.resolve<T>(this.http.get<WebApiInterface<T>>(`${WebAPIDomain}${path}`, options), isEmptyResponse).catch(
      (error: any) => {
        console.log(`Error on get request with path=${path}`, options, error.message);
        throw error;
      },
    );
  }

  public post<T>(
    path: string,
    body: any | null,
    options?: Parameters<HttpClient['post']>[2],
    isEmptyResponse?: boolean,
  ): Promise<T>;

  public post<T>(
    path: string,
    body: any | null,
    options?: Parameters<HttpClient['post']>[2],
    isEmptyResponse?: boolean,
  ): Promise<T | WebApiInterface<T>> {
    if (this.sessionService.sessionGlobal?.csrf) {
      let headers = new HttpHeaders({ 'X-CSRF-TOKEN': this.sessionService.sessionGlobal.csrf });
      const newOptions = {
        headers,
        ...(options || {}),
      };
      return this.resolve<T>(
        this.http.post<WebApiInterface<T>>(`${WebAPIDomain}${path}`, body, newOptions),
        isEmptyResponse,
      ).catch((error: any) => {
        console.log(`Error on post request with path=${path}`, options, error.message);
        throw error;
      });
    } else {
      throw new Error('Отсутствует CSRF токен');
    }
  }

  public authorize<T>(): Promise<T>;

  public authorize<T>(): Promise<T | WebApiInterface<T>> {
    this.cookieService.removeAll();
    this.sessionService.sessionGlobal?.cookieString.split('; ').forEach(cookie => {
      this.cookieService.put(cookie.split('=')[0], cookie.split('=')[1]);
    });
    return this.resolve<T>(
      this.http.get<WebApiInterface<T>>(`${WebAPIDomain}webapi/info/account`, { withCredentials: true }),
    );
  }

  private resolve<T>(
    observable: Observable<WebApiInterface<T>>,
    isEmptyResponse?: boolean,
    raw = false,
  ): Promise<T | WebApiInterface<T>> {
    if (isEmptyResponse) {
      return lastValueFrom(observable.pipe(switchMap(response => of(response))));
    }

    return lastValueFrom(
      observable.pipe(
        switchMap(response => {
          if (raw) {
            return of(response);
          }

          if (!response.success) {
            let errorMessage: string = '';
            if (response.errors && !Object.keys(response.errors).length) {
              for (let key of Object.keys(response.errors)) {
                errorMessage += '\n' + response.errors[key];
              }
            }
            return throwError(() => {
              const error: any = new Error(response.message + errorMessage);
              error.statusCode = 200;
              return error;
            });
          }

          if (!response.data) {
            console.log('Empty API response');
            return of(response);
          }

          return of(response.data);
        }),
      ),
    );
  }
}
