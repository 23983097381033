<fieldset class="field-radio-buttons">
  <div class="field-radio-button" *ngFor="let value of values">
    <input
      name="visitType"
      type="radio"
      [value]="value"
      [id]="value + 'Id'"
      [(ngModel)]="formData[formDataField]"
    >
    <label [for]="value + 'Id'">{{ value }}</label>
  </div>
  <div class="modal-form-hint" [class.hidden]="!(!formData[formDataField] && isError)">{{ errorText }}</div>
</fieldset>
