<div class="visits-modal-end-save-wrapper">
  <h1 class="visits-modal-end-save-header">Сохранить черновик визита при выходе из аккаунта?</h1>
  <h2 class="visits-modal-end-save-info">Вы можете сохранить черновик, чтобы использовать его позднее</h2>
  <div [hidden]="!isInternalError" class="modal-internal-error">
    Мы сожалеем, произошел сбой в системе<br>
    Попробуйте еще раз спустя некоторое время
  </div>
  <div class="visits-modal-end-save-actions">
    <button class="visits-modal-end-save-action-no" (click)="visitsService.closeModal()">Не сохранять</button>
    <button class="visits-modal-end-save-action-save" [disabled]="isInternalError" (click)="saveVisitToDrafts()">Сохранить и выйти</button>
  </div>
</div>
