<div *ngIf="!visitsService.isMobile; else mobileContent" class="content-wrapper">
  <div class="content-section users">
    <div class="users-participant-search">
      <input type="text" placeholder="Поиск" [(ngModel)]="filterText" (input)="filterClients()">
    </div>
    <ul
      class="users-list"
      [class.popup-size]="isPopup"
      #clientsList
    >
      <li *ngFor="let client of filteredClients"
          class="users-list-item"
          [class.active]="isClientSelected(client)"
          (click)="selectClient(client)"
      >
        {{ client.name | stringCutWithDots : getMaxOneLineTextLength() }}
      </li>
    </ul>
    <button class="users-list-new" (click)="createNewClient()">Новый</button>
  </div>
  <div class="content-section selected-client-info">
    <div class="selected-client-info user-info">
      <div class="user-info-header">
        <img
          src="../../../../../assets/images/avatar.png" alt="Аватар участника"
          class="user-info-header-avatar"
        >
        <div class="user-info-header-details">
          <h2>{{ selectedClient?.name ? selectedClient?.name : "" }}</h2>
          <h3>{{ selectedClient?.email ? selectedClient?.email : "" }}</h3>
        </div>
      </div>
      <button class="user-info-start" (click)="startVisit()">Начать визит</button>
    </div>
    <div class="separator" *ngIf="!isPopup"></div>
    <div class="selected-client-info client-info"  *ngIf="!isPopup">
      <div class="client-info-header">
        <h2 class="client-info-header-title">Информация</h2>
        <div class="client-info-header-details">
          <div class="client-info-header-details-subtitle">Последний визит</div>
          <div class="client-info-header-details-date">
            {{  visitsService.convertDateToString(selectedClient?.lastVisit, 'date-time') }}
          </div>
        </div>
      </div>
      <div class="client-info-stats">
        <div class="client-info-stats-item">
          Визитов
          <span>{{ selectedClient?.visitsCount }}</span>
        </div>
        <div class="client-info-stats-item">
          Заказов
          <span>{{ selectedClient?.ordersCount }}</span>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #mobileContent>
  <div class="content-section users">
    <div class="users-participant-search">
      <input type="text" placeholder="Поиск" [(ngModel)]="filterText" (input)="filterClients()">
    </div>
    <button class="users-list-new" (click)="createNewClient()">Новый</button>
    <ul
      class="users-list"
      #visitsList
    >
      <div *ngFor="let client of filteredClients" class="list-item-wrapper" [class.active]="isClientSelected(client)">
        <li
            class="users-list-item"
            [class.active]="isClientSelected(client)"
            (click)="selectClient(client)"
        >
          {{ isClientSelected(client) ? '' : client.name }}
        </li>
        <div
          class="users-list-info"
          *ngIf="isClientSelected(client)"
        >
          <div class="users-list-info-header">
            <h2 class="users-list-info-header-name">{{ client.name }}</h2>
            <h3 class="users-list-info-header-mail">{{ client.email }}</h3>
            <button class="users-list-info-header-start" (click)="startVisit()">Начать визит</button>
          </div>
          <div class="users-list-info-data">
            <h2 class="users-list-info-data-title">Информация</h2>
            <div class="users-list-info-data-details">
              <div class="client-info-header-details-subtitle">Последний визит</div>
              <div class="client-info-header-details-date">
                {{ visitsService.convertDateToString(client.lastVisit, 'date-time') }}
              </div>
            </div>
          </div>
          <div class="users-list-info-stats">
            <div class="users-list-info-stats-item">
              Визитов
              <span>{{ client.visitsCount }}</span>
            </div>
            <div class="users-list-info-stats-item">
              Заказов
              <span>{{ client?.ordersCount }}</span>
            </div>
          </div>
        </div >
      </div>

    </ul>
  </div>
</ng-template>
