import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './modules/layout/layout.module';
import { VisitsModule } from './modules/visits/visits.module';
import { ApiModule } from './core/api/api.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApolloModule } from 'apollo-angular';
import { AppSessionService } from './core/app-session-service/app-session.service';
import { CookieModule, CookieService } from '@gorniv/ngx-universal';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { AuthorisationService } from './core/authorisation/authorisation.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CookieModule.forRoot(),
    LayoutModule,
    VisitsModule,
    AppRoutingModule,
    ApiModule,
    HttpClientModule,
    ApolloModule,
    HttpClientModule,
  ],
  providers: [
    AppSessionService,
    AuthorisationService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
