import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebApiService } from './shared/services/web-api.service';
import { ApiService } from './api.service';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpClientModule } from '@angular/common/http';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { GraphQLAPIDomain } from '../../app-export.constant';
import { GraphqlApiService } from './shared/services/graphql-api.service';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

function createApollo(httpLink: HttpLink) {
  const basic = setContext((operation, context) => {
    return {
      headers: {
        Accept: 'charset=base64',
      },
    };
  });
  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('token');
    if (token !== null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vZXVyby1wcmVzdGlnZS5sb2NhbCIsImlhdCI6MTY2ODUzMzA3NywiZXhwIjoxNjY4NzQ5MDc3LCJuYmYiOjE2Njg1MzMwNzcsImp0aSI6InVOQjBMb21nZlFOOGszbDUiLCJzdWIiOiIxNjQxIiwicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSIsInVzZXJfZW1haWwiOiJ0ZXN0MkBldXJvLXByZXN0aWdlLnJ1IiwidXNlcl9yb2xlIjoiTUFOQUdFUiJ9.bOJGspNzz70lBCoBnkSKQYy4wZbIVy0q0l4HhNIOQLg`,
        },
      };
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(value => {
        console.log(`[GraphQL API Error]: Message: ${value.message}, Path: ${value.path}`);
        console.log(value);
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const rawHttpLink = ApolloLink.from([basic, auth, httpLink.create({ uri: GraphQLAPIDomain })]);
  const link = errorLink.concat(rawHttpLink);
  const cache = new InMemoryCache();

  return {
    link,
    cache,
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule, ApolloModule, HttpClientModule],
  providers: [
    WebApiService,
    ApiService,
    GraphqlApiService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class ApiModule {}
