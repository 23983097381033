import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { VisitsApiService } from './visits-api.service';
import {
  VisitsClient,
  VisitsClientGraphqlInfoInterface,
  VisitsClientsGraphqlResponseInterface,
  VisitsClientWebapiInfoInterface,
} from '../interfaces/visits-clients-graphql-response.interface';
import {
  getClientsInfo,
  getDraftedVisits,
  getGoals,
  getOpenedVisit,
  getReachings,
  getVisitDetail,
  getVisits,
} from '../constants/gql-queries.constant';
import {
  VisitsJournalDataGraphqlResponseInterface,
  VisitsJournalDataInterface,
} from '../interfaces/visits-journal-data.interface';
import {
  VisitsGoalDataGraphqlResponseInterface,
  VisitsGoalsReachingsInterface,
  VisitsReachingsDataGraphqlResponseInterface,
} from '../interfaces/visits-goal-reachings-data.interface';
import {
  openedVisitInfoGraphqlResponseInterface,
  VisitInfoGraphqlResponseInterface,
  VisitInfoInterface,
} from '../interfaces/visit-info.interface';
import {
  VisitsDraftedDataInterface,
  VisitsDraftedGraphqlResponseInterface,
} from '../interfaces/visits-drafted-data.interface';
import {
  GoalInput,
  VisitsCloseMutationResponseInterface,
  VisitsCreateMutationResponseInterface,
  VisitsDeleteMutationResponseInterface,
  VisitsDraftMutationResponseInterface,
  VisitsOpenMutationResponseInterface,
  VisitsReopenMutationResponseInterface,
} from '../interfaces/visits-mutations-response.interface';
import {
  closeVisit,
  createClient,
  deleteDraftedVisit,
  draftVisit,
  openVisit,
  reopenVisit,
} from '../constants/gql-mutations.constant';
import { AppSessionService } from '../../../../core/app-session-service/app-session.service';
import { VisitOrderInfoInterface } from '../interfaces/visit-order-info.interface';
import { DateStringToDatePipe } from '../pipes/date-string-to-date.pipe';
import { VisitSetAddressInterface } from '../interfaces/visit-set-address.interface';
import { VisitSetWarehouseInterface } from '../interfaces/visit-set-warehouse.interface';

@Injectable()
export class VisitsService {
  public isMobile: boolean = false;
  public innerHeight: number = window.innerHeight;
  public innerWidth: number = window.innerWidth;
  public goalsAndReachings?: VisitsGoalsReachingsInterface;

  constructor(
    private visitsApiService: VisitsApiService,
    @Inject(PLATFORM_ID) private platformId: NodeJS.Platform,
    private sessionService: AppSessionService,
    private datePipe: DateStringToDatePipe,
  ) {}

  public async logout() {
    await this.visitsApiService.magicLogoutUser();
  }

  public convertDateToString(str: string | undefined, type: 'date' | 'date-time' | 'time') {
    if (!str) {
      return '';
    }
    const date = this.datePipe.transform(str);
    const format = new Intl.NumberFormat('ru', { minimumIntegerDigits: 2 });

    const transformer = {
      'date-time'(date: Date) {
        return `${format.format(date.getHours())}:${format.format(date.getMinutes())}  ${format.format(
          date.getDate(),
        )}-${format.format(date.getMonth() + 1)}-${date.getFullYear()}`;
      },
      time(date: Date) {
        return `${format.format(date.getHours())}:${format.format(date.getMinutes())}`;
      },
      date(date: Date) {
        return `${format.format(date.getDate())}-${format.format(date.getMonth() + 1)}-${date.getFullYear()}`;
      },
    };

    return transformer[type](date);
  }

  // getting data
  public async getDraftedVisits(): Promise<VisitsDraftedDataInterface[]> {
    const rawGqlResp: VisitsDraftedGraphqlResponseInterface =
      await this.visitsApiService.fetchGraphqlDataWithoutData<VisitsDraftedGraphqlResponseInterface>(getDraftedVisits);
    return rawGqlResp.getDraftedVisits;
  }

  public async getClientInfo(email: string) {
    return (await this.visitsApiService.fetchMagicClientsInfo([email]))[0];
  }

  public async getClientsData(): Promise<VisitsClient[]> {
    const rawGqlResp: VisitsClientsGraphqlResponseInterface =
      await this.visitsApiService.fetchGraphqlDataWithoutData<VisitsClientsGraphqlResponseInterface>(getClientsInfo);
    const rawGraphqlClientsInfo: VisitsClientGraphqlInfoInterface[] = rawGqlResp.getClientsInfo;
    const rawWebapiClientsInfo: VisitsClientWebapiInfoInterface[] = await this.visitsApiService.fetchMagicClientsInfo(
      rawGraphqlClientsInfo.map(client => client.client_email),
    );

    return rawWebapiClientsInfo
      .filter(client => rawGraphqlClientsInfo.find(clientInfo => clientInfo.client_email === client.email))
      .map(client => {
        const graphqlClientInfo: VisitsClientGraphqlInfoInterface | undefined = rawGraphqlClientsInfo.find(
          clientInfo => clientInfo.client_email === client.email,
        );

        if (graphqlClientInfo) {
          return {
            email: client.email,
            name: client.name,
            phone: client.phone,
            visitsCount: graphqlClientInfo.visits_count,
            ordersCount: graphqlClientInfo.orders_count,
            lastVisit: graphqlClientInfo.last_visit,
          };
        } else {
          return {
            email: client.email,
            name: client.name,
            phone: client.phone,
            visitsCount: NaN,
            ordersCount: NaN,
            lastVisit: '',
          };
        }
      });
  }

  public async getGoalsAndReachings(): Promise<VisitsGoalsReachingsInterface> {
    const rawGoalsGqlResp: VisitsGoalDataGraphqlResponseInterface =
      await this.visitsApiService.fetchGraphqlDataWithoutData<VisitsGoalDataGraphqlResponseInterface>(getGoals);
    const rawReachingsGqlResp: VisitsReachingsDataGraphqlResponseInterface =
      await this.visitsApiService.fetchGraphqlDataWithoutData<VisitsReachingsDataGraphqlResponseInterface>(
        getReachings,
      );
    return {
      goals: rawGoalsGqlResp.getGoals,
      reachings: rawReachingsGqlResp.getReachings,
    };
  }

  public async getVisitDetails(id: number): Promise<VisitInfoInterface> {
    const rawGqlResp: VisitInfoGraphqlResponseInterface =
      await this.visitsApiService.fetchGraphqlDataWithData<VisitInfoGraphqlResponseInterface>(getVisitDetail, { id });
    return rawGqlResp.getVisitDetail;
  }

  public async getOpenedVisit(): Promise<VisitInfoInterface | null> {
    const rawGqlResp: openedVisitInfoGraphqlResponseInterface =
      await this.visitsApiService.fetchGraphqlDataWithoutData<openedVisitInfoGraphqlResponseInterface>(getOpenedVisit);
    return rawGqlResp.getOpenedVisit || null;
  }

  public async getVisits(): Promise<VisitsJournalDataInterface[]> {
    const rawGqlResp: VisitsJournalDataGraphqlResponseInterface =
      await this.visitsApiService.fetchGraphqlDataWithoutData<VisitsJournalDataGraphqlResponseInterface>(getVisits);
    return rawGqlResp.getVisits;
  }

  // visits actions
  public closeModal(isNeededRefresh?: boolean) {
    if (this.sessionService.sessionModal?.lastIframeId) {
      this.sessionService.sendResponseToIframe(
        false,
        null,
        '',
        this.sessionService.sessionModal.lastIframeId,
        NaN,
        isNeededRefresh,
      );
    } else {
      throw new Error('[VISIT APP] lastIframeId is needed to this modal type.');
    }
  }

  public async closeVisit(id: number, reaching_id: number, reaching_comment: string): Promise<boolean> {
    const rawGqlResp: VisitsCloseMutationResponseInterface =
      await this.visitsApiService.mutateGraphqlData<VisitsCloseMutationResponseInterface>(closeVisit, {
        id,
        reaching_id,
        reaching_comment,
      });
    return rawGqlResp.closeVisit;
  }

  public async createNewClient(email: string, name: string) {
    return await this.visitsApiService.registerClient(email, name);
  }

  public async createClient(email: string): Promise<number> {
    const rawGqlResp: VisitsCreateMutationResponseInterface =
      await this.visitsApiService.mutateGraphqlData<VisitsCreateMutationResponseInterface>(createClient, {
        email,
      });
    return rawGqlResp.createClient;
  }

  public async deleteDrafted(id: number): Promise<boolean> {
    const rawGqlResp: VisitsDeleteMutationResponseInterface =
      await this.visitsApiService.mutateGraphqlData<VisitsDeleteMutationResponseInterface>(deleteDraftedVisit, {
        id,
      });
    return rawGqlResp.deleteDrafted;
  }

  public async draftVisit(id: number): Promise<boolean> {
    const rawGqlResp: VisitsDraftMutationResponseInterface =
      await this.visitsApiService.mutateGraphqlData<VisitsDraftMutationResponseInterface>(draftVisit, { id });
    return rawGqlResp.draftVisit;
  }

  public async getOrderBasicInfo(orders: number[]): Promise<VisitOrderInfoInterface[]> {
    return await this.visitsApiService.fetchVisitOrdersInfo(orders);
  }

  public async getClientAddresses(email: string) {
    return await this.visitsApiService.fetchClientAddresses(email);
  }

  public async getClientWarehouses(email: string) {
    return await this.visitsApiService.fetchClientWarehouses(email);
  }

  public async openVisit(type: 'Online' | 'Offline', client_email: string, goal: GoalInput): Promise<number> {
    const rawGqlResp: VisitsOpenMutationResponseInterface =
      await this.visitsApiService.mutateGraphqlData<VisitsOpenMutationResponseInterface>(openVisit, {
        type,
        client_email,
        goal,
      });
    return rawGqlResp.openVisit;
  }

  public async reopenVisit(id: number): Promise<boolean> {
    const rawGqlResp: VisitsReopenMutationResponseInterface =
      await this.visitsApiService.mutateGraphqlData<VisitsReopenMutationResponseInterface>(reopenVisit, { id });
    return rawGqlResp.reopenVisit;
  }

  public async setClientAddresses(visitSetAddressParams: VisitSetAddressInterface) {
    await this.visitsApiService.sendClientAddresses(visitSetAddressParams);
  }

  public async setClientWarehouse(visitSetWarehouseParams: VisitSetWarehouseInterface) {
    await this.visitsApiService.sendClientWarehouse(visitSetWarehouseParams);
  }
}
