import { Component, Input } from '@angular/core';
import { VisitFormDataInterface } from '../../../shared/interfaces/visit-form-data.interface';

@Component({
  selector: 'app-visits-modal-form-input',
  templateUrl: './visits-modal-form-input.component.html',
  styleUrls: ['./visits-modal-form-input.component.scss'],
})
export class VisitsModalFormInputComponent {
  @Input() public formData!: VisitFormDataInterface;
  @Input() public formDataField!: string;
  @Input() public title!: string;
  @Input() public isError!: boolean | string;
  @Input() public isSmall?: boolean;

  constructor() {}

  public get errorText(): string {
    return typeof this.isError === 'string' ? this.isError : 'Заполните поле';
  }
}
