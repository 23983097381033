import { gql, TypedDocumentNode } from 'apollo-angular';

export const createClient: TypedDocumentNode = gql`
  mutation createClient($email: String!) {
    createClient(email: $email)
  }
`;

export const openVisit: TypedDocumentNode = gql`
  mutation openVisit($type: String!, $client_email: String!, $goal: GoalInput!, $coordinates: CoordinatesInput) {
    openVisit(type: $type, client_email: $client_email, goal: $goal, coordinates: $coordinates)
  }
`;

export const closeVisit: TypedDocumentNode = gql`
  mutation closeVisit($id: Int!, $reaching_id: Int!, $reaching_comment: String!) {
    closeVisit(id: $id, reaching_id: $reaching_id, reaching_comment: $reaching_comment)
  }
`;

export const draftVisit: TypedDocumentNode = gql`
  mutation draftVisit($id: Int!) {
    draftVisit(id: $id)
  }
`;

export const reopenVisit: TypedDocumentNode = gql`
  mutation reopenVisit($id: Int!) {
    reopenVisit(id: $id)
  }
`;

export const deleteDraftedVisit: TypedDocumentNode = gql`
  mutation deleteDraftedVisit($id: Int!) {
    deleteDraftedVisit(id: $id)
  }
`;
