<div class="visits-list-details" [class.mobile]="visitsService.isMobile">
  <div class="visits-list-details-info">
    <div class="visits-list-details-info-line">
      <div class="visits-list-details-info-line-title">Визит открыт для</div>
      <div class="visits-list-details-info-line-text name">
        {{ visitClientInfo?.name ? visitClientInfo?.name : '' }}<span>{{ visitClientInfo?.email ? visitClientInfo?.email : '' }}</span>
      </div>
    </div>
    <div class="visits-list-details-info-line">
      <div class="visits-list-details-info-line-title">Время начала визита</div>
      <div class="visits-list-details-info-line-text">
        {{ visitsService.convertDateToString(visitDetails?.time_start, 'date-time') }}
      </div>
    </div>
    <div *ngIf="visitDetails?.time_end" class="visits-list-details-info-line">
      <div class="visits-list-details-info-line-title">Время окончания визита</div>
      <div class="visits-list-details-info-line-text">
        {{ visitsService.convertDateToString(visitDetails?.time_end, 'date-time') }}
      </div>
    </div>
    <div class="visits-list-details-info-line">
      <div class="visits-list-details-info-line-title">Вид визита</div>
      <div class="visits-list-details-info-line-text">{{ getCapitalizedFirstLetterWord(this.visitDetails?.type) }}</div>
    </div>
    <div class="visits-list-details-info-line">
      <div class="visits-list-details-info-line-title">Цель визита</div>
      <div class="visits-list-details-info-line-text">{{ this.visitDetails?.goal_comment }}</div>
    </div>
    <div *ngIf="visitDetails?.orders" class="visits-list-details-info-line orders">
      <div class="visits-list-details-info-line-title">Сделанные заказы</div>
      <div class="visits-list-details-info-line-text orders">
        <div *ngFor="let order of visitOrders" class="visits-list-details-info-line-order">
          <a href="#">Заказ №{{ order.external_id }}</a>
          <span>от {{ visitsService.convertDateToString(order.created_at, 'date-time') }}</span>
        </div>
        <div *ngIf="!visitOrders.length" class="visits-list-details-info-line-text">Не удалось загрузить заказы</div>
      </div>
      <div *ngIf="!visitDetails?.orders" class="visits-list-details-info-line-text orders">
          Заказов нет
      </div>
    </div>
    <div
      class="visits-list-details-buttons"
      *ngIf="visitDetails?.status === 'opened'"
    >
      <button
        class="visits-list-details-buttons-item"
        (click)="closeVisit()"
      >
        Завершить визит
      </button>
    </div>
  </div>
</div>
