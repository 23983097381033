<div *ngIf="visitsService.innerWidth > 585 else mobileTable" class="visits-table-wrapper">
  <div
    class="table-wrapper"
    *ngIf="visitsService.innerWidth > 750"
    [style]="{ 'grid-template-columns': 'repeat(2, auto) minmax(110px, 475px) 190px 95px', width: '100%'}"
  >
    <div
      class="visits-table-content header"
      *ngFor="let title of titles; let i = index"
      [class.first]="i === 0"
      [class.last]="i === titles.length - 1"
      [class.buttons]="i >= titles.length - 2"
    >
      {{ title }}
    </div>
    <ng-container *ngFor="let row of rows;  let rowIndex = index">
      <ng-container *ngFor="let cell of row?.rowData; let i = index">
        <div
          class="visits-table-content"
          [class.first]="i === 0"
        >
          {{ cell.text }}
        </div>
      </ng-container>
      <button
        class="visits-table-content continue"
        (click)="reopenVisit(draftedDate[rowIndex]?.client_email, draftedDate[rowIndex]?.id)"
      >
        Продолжить визит
      </button>
      <button
        class="visits-table-content delete"
        (click)="deleteVisit(draftedDate[rowIndex]?.client_email, draftedDate[rowIndex]?.id)"
      >
        Удалить
      </button>
    </ng-container>
  </div>
  <div
    class="table-wrapper"
    *ngIf="visitsService.innerWidth <= 750"
    [style]="{ 'grid-template-columns': 'repeat(1, auto) minmax(110px, 475px) 190px 95px', width: '100%'}"
  >
    <div
      class="visits-table-content header"
      *ngFor="let title of lowResolutionTitles let i = index"
      [class.first]="i === 0"
      [class.last]="i === lowResolutionTitles.length - 1"
      [class.buttons]="i >= lowResolutionTitles.length - 2"
    >
      {{ title }}
    </div>
    <ng-container *ngFor="let row of lowResolutionRows; let rowIndex = index">
      <ng-container *ngFor="let cell of row?.rowData; let i = index">
        <div
          class="visits-table-content"
          [class.first]="i === 0"
          [innerHTML]="cell.text"
        >
        </div>
      </ng-container>
      <button
        class="visits-table-content continue"
        (click)="reopenVisit(draftedDate[rowIndex]?.client_email, draftedDate[rowIndex]?.id)"
      >
        Продолжить визит
      </button>
      <button
        class="visits-table-content delete"
        (click)="deleteVisit(draftedDate[rowIndex]?.client_email, draftedDate[rowIndex]?.id)"
      >
        Удалить
      </button>
    </ng-container>
  </div>
</div>

<ng-template #mobileTable>
  <div class="visits-mobile-table">
    <div
      *ngFor="let cell of rows; let rowIndex = index"
      class="visits-mobile-table-cell"
    >
      <div
        *ngFor="let cellData of cell.rowData; let i = index"
        class="visits-mobile-table-cell-row"
      >
        <div class="visits-mobile-table-cell-row-title">{{ titles[i] }}</div>
        <div class="visits-mobile-table-cell-row-subtitle">{{ cellData.text }}</div>
      </div>
      <div class="visits-mobile-table-cell-row buttons">
        <button
          class="button-delete"
          (click)="deleteVisit(draftedDate[rowIndex]?.client_email, draftedDate[rowIndex]?.id)"
        >
          Удалить
        </button>
        <button
          class="button-continue"
          (click)="reopenVisit(draftedDate[rowIndex]?.client_email, draftedDate[rowIndex]?.id)"
        >
          Продолжить визит
        </button>
      </div>
    </div>
  </div>
</ng-template>
