<div class="visits-popup-header">
  Сейчас открыт визит без<br>
  подключения к аккаунту
</div>
<div class="visits-popup-info">
  Вы хотите завершить визит и продолжить работу?
</div>
<div class="visits-popup-actions">
  <button class="visits-popup-actions-exit" (click)="saveDraft()">Выйти</button>
  <button class="visits-popup-actions-end" (click)="closeVisit()">Завершить визит</button>
</div>
