import { Component, OnInit } from '@angular/core';
import { AppSessionService } from './core/app-session-service/app-session.service';
import { SessionOptionsInterface } from './core/interfaces/message-data/session-options.interface';
import { ApiService } from './core/api/api.service';
import { Router } from '@angular/router';
import { AuthorisationService } from './core/authorisation/authorisation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private appService: AppSessionService,
    private api: ApiService,
    private router: Router,
    private authorisationService: AuthorisationService,
  ) {}

  async ngOnInit() {
    window.addEventListener('message', async event => {
      if (event.data.title === 'ngVisits') {
        await this.appService.parseOptions(event.data as SessionOptionsInterface);
        this.authorisationService.authorize();
      }
    });
  }
}
