import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AuthorisationDataInterface } from './interfaces/autorisation.interface';

@Injectable()
export class AuthorisationService {
  private authorisationData?: AuthorisationDataInterface | 'loading' | 'error' = 'loading';

  constructor(private api: ApiService) {}

  public async authorize() {
    if (typeof this.authorisationData !== 'object') {
      try {
        this.authorisationData = await this.api.webApi.authorize<AuthorisationDataInterface>();
      } catch {
        this.authorisationData = 'error';
      }
    }
  }

  public get authorizeStatus(): 'authorized' | 'loading' | 'error' {
    if (typeof this.authorisationData === 'object') {
      return this.authorisationData.isAuth ? 'authorized' : 'error';
    } else {
      return this.authorisationData as 'loading' | 'error';
    }
  }

  public get accountName(): string {
    if (typeof this.authorisationData === 'object') {
      return this.authorisationData?.isAuth ? this.authorisationData.accountName : '';
    } else {
      return '';
    }
  }

  public get isAuth(): boolean {
    if (typeof this.authorisationData === 'object') {
      return this.authorisationData?.isAuth;
    } else {
      return false;
    }
  }
}
