import { Component } from '@angular/core';

@Component({
  selector: 'app-visits-modal-end',
  templateUrl: './visits-modal-end.component.html',
  styleUrls: ['./visits-modal-end.component.scss'],
})
export class VisitsModalEndComponent {
  constructor() {}
}
