import { Component, OnInit } from '@angular/core';
import { VisitsService } from '../../../shared/services/visits.service';
import { RowData } from '../../../shared/interfaces/table-data.interface';
import { VisitsDraftedDataInterface } from '../../../shared/interfaces/visits-drafted-data.interface';
import { AppSessionService } from '../../../../../core/app-session-service/app-session.service';
import { VisitsClientWebapiInfoInterface } from '../../../shared/interfaces/visits-clients-graphql-response.interface';

@Component({
  selector: 'app-visits-drafts',
  templateUrl: './visits-drafts.component.html',
  styleUrls: ['./visits-drafts.component.scss'],
})
export class VisitsDraftsComponent implements OnInit {
  public titles: string[] = ['Дата', 'Время сохранения', 'Пользователь', 'Продолжить визит', ''];
  public lowResolutionTitles: string[] = ['Дата/Время', 'Пользователь', 'Продолжить визит', ''];
  public lowResolutionRows: RowData[] = [];
  public clientsData: VisitsClientWebapiInfoInterface[] = [];
  public rows: RowData[] = [];
  public draftedDate: VisitsDraftedDataInterface[] = [];

  constructor(public visitsService: VisitsService, private sessionService: AppSessionService) {}

  async ngOnInit() {
    await this.generateDraftedTableData();
  }

  public reopenVisit(email: string | undefined, visitId: number | undefined) {
    if (email && visitId) {
      this.sessionService.sendResponseToIframe(true, 'continue', email, '', visitId);
    }
  }

  public deleteVisit(email: string | undefined, visitId: number | undefined) {
    if (email && visitId) {
      this.sessionService.sendResponseToIframe(true, 'delete', email, '', visitId);
    }
  }

  private async generateDraftedTableData() {
    this.draftedDate = await this.visitsService.getDraftedVisits();
    for (const row of this.draftedDate) {
      this.rows.push(await this.getDraftedTableRowData(row));
      this.lowResolutionRows.push(await this.getDraftedLowResolutionTableRowData(row));
    }
  }

  private async getDraftedTableRowData(rowData: VisitsDraftedDataInterface): Promise<RowData> {
    const clientInfo = await this.visitsService.getClientInfo(rowData.client_email);
    this.clientsData.push(clientInfo);
    return {
      rowData: [
        {
          text: `${this.visitsService.convertDateToString(rowData.created_at, 'date')}`,
        },
        {
          text: `${this.visitsService.convertDateToString(rowData.time_draft, 'time')}`,
        },
        {
          text: clientInfo.name,
        },
      ],
    };
  }

  private async getDraftedLowResolutionTableRowData(rowData: VisitsDraftedDataInterface): Promise<RowData> {
    const clientInfo = await this.visitsService.getClientInfo(rowData.client_email);
    return {
      rowData: [
        {
          text:
            `${this.visitsService.convertDateToString(rowData.created_at, 'date')}` +
            '<br>' +
            `${this.visitsService.convertDateToString(rowData.time_draft, 'time')}`,
        },
        {
          text: clientInfo.name,
        },
      ],
    };
  }
}
