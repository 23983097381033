import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/api/api.service';
import { TypedDocumentNode } from 'apollo-angular';
import { GraphqlVariablesInterface } from '../../../../core/api/shared/interfaces/graphql-api.interface';
import { VisitsClientWebapiInfoInterface } from '../interfaces/visits-clients-graphql-response.interface';
import { VisitOrderInfoInterface } from '../interfaces/visit-order-info.interface';
import { ClientAddressesInterface } from '../interfaces/client-addresses.interface';
import { ClientWarehousesInterface } from '../interfaces/client-warehouses.interface';
import { HttpClient } from '@angular/common/http';
import { VisitSetAddressInterface } from '../interfaces/visit-set-address.interface';
import { VisitSetWarehouseInterface } from '../interfaces/visit-set-warehouse.interface';

@Injectable()
export class VisitsApiService {
  constructor(private api: ApiService, private http: HttpClient) {}

  public fetchGraphqlDataWithoutData<T>(gql: TypedDocumentNode): Promise<T> {
    return this.api.graphApi.query<T>(gql);
  }

  public fetchGraphqlDataWithData<T>(gql: TypedDocumentNode, variables: GraphqlVariablesInterface): Promise<T> {
    return this.api.graphApi.query<T>(gql, variables);
  }

  public mutateGraphqlData<T>(gql: TypedDocumentNode, variables: GraphqlVariablesInterface): Promise<T> {
    return this.api.graphApi.mutate<T>(gql, variables);
  }

  public async fetchMagicClientsInfo(emails: string[]) {
    return this.api.webApi.get<VisitsClientWebapiInfoInterface[]>('users', {
      params: { 'emails[]': emails },
    });
  }

  public async fetchVisitOrdersInfo(orders: number[]) {
    return this.api.webApi.get<VisitOrderInfoInterface[]>('account/orders/orders-info', {
      params: { 'order_ids[]': orders },
    });
  }

  public async fetchClientAddresses(email: string) {
    return this.api.webApi.get<ClientAddressesInterface>('webapi/widget/clientAddresses', {
      params: {
        email,
      },
    });
  }

  public async sendClientAddresses(visitSetAddressParams: VisitSetAddressInterface) {
    return this.api.webApi.post<any>('webapi/widget/clientAddress', {
      ...visitSetAddressParams,
    });
  }

  public async sendClientWarehouse(visitSetWarehouseParams: VisitSetWarehouseInterface) {
    return this.api.webApi.post<any>('webapi/widget/clientWarehouse', {
      ...visitSetWarehouseParams,
    });
  }

  public async fetchClientWarehouses(email: string) {
    return this.api.webApi.get<ClientWarehousesInterface>('webapi/widget/clientWarehouses', {
      params: {
        email,
      },
    });
  }

  public async registerClient(email: string, name: string) {
    return this.api.webApi.post('webapi/widget/register', {
      email,
      name,
    });
  }

  public magicLogoutUser() {
    return this.api.webApi.get('logout', {}, true);
  }
}
