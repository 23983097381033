import { Component } from '@angular/core';
import { AppSessionService } from '../../core/app-session-service/app-session.service';
import { AuthorisationService } from '../../core/authorisation/authorisation.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  constructor(public sessionService: AppSessionService, public authorisationService: AuthorisationService) {}
}
