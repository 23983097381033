<div class="express-panel" [style]="{height: visitsService.innerHeight + 'px'}">
  <h1 class="express-panel-title">Визиты</h1>
  <div class="express-panel-states">
    <nav class="express-panel-states-switcher" [class.low-reso]="visitsService.innerWidth < 420">
      <button
        class="express-panel-states-switcher-item"
        (click)="switchPanelContentPage('')"
        [class.active]="currentPath === ''"
      >
        Мои визиты
      </button>
      <button
        class="express-panel-states-switcher-item"
        (click)="switchPanelContentPage('list')"
        [class.active]="currentPath.includes('list')"
      >
        Журнал визитов
      </button>
      <button
        class="express-panel-states-switcher-item"
        (click)="switchPanelContentPage('drafts')"
        [class.active]="currentPath === 'drafts'"
      >
        Черновик
      </button>
    </nav>
  </div>
  <div class="express-panel-content" [class.no-border]="currentPath">
    <router-outlet></router-outlet>
  </div>
</div>
